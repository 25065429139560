var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { hasDefault: !!_vm.value.length },
      attrs: { id: "common-file-uploader" },
    },
    [
      _c(
        "uploader",
        {
          ref: "uploader",
          staticClass: "uploader-app",
          attrs: {
            options: _vm.options,
            autoStart: false,
            "file-status-text": _vm.statusText,
          },
          on: {
            "file-added": _vm.onFileAdded,
            "file-success": _vm.onFileSuccess,
            "file-progress": _vm.onFileProgress,
            "file-error": _vm.onFileError,
            complete: _vm.onComplete,
          },
        },
        [
          _c("uploader-unsupport"),
          _c(
            "uploader-btn",
            {
              ref: "uploadBtn",
              attrs: { id: "file-uploader-btn", attrs: _vm.attrs },
            },
            [_vm._v("选择文件")]
          ),
          _c("uploader-list", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ fileList }) {
                  return [
                    _c(
                      "ul",
                      {
                        staticClass:
                          "el-upload-list el-upload-list el-upload-list--text",
                      },
                      _vm._l(fileList, function (file) {
                        return _c(
                          "li",
                          { key: file.id, staticClass: "el-upload-list__item" },
                          [
                            _c("uploader-file", {
                              ref: "files",
                              refInFor: true,
                              class: "file_" + file.id,
                              attrs: { file: file, list: true },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (props) {
                                      return [
                                        _c("FileItem", {
                                          attrs: {
                                            file: props.file,
                                            list: props.list,
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }