<template>
  <div style="height: calc(100vh - 120px);">
    <component :is="currentView" :config="config" :modelId="modelId" :isPreview="isPreview"
               v-if="showPage"/>
  </div>

</template>

<script>
import {listData} from '@/api/systemData/abilityDesign';
import {getConfigData} from '@/api/onlineDev/visualDev'
import Form from './form'
import List from './list'
import {mapGetters} from "vuex";

export default {
  name: 'dynamicModel',
  components: {Form, List},
  data() {
    return {
      currentView: '',
      showPage: false,
      isPreview: false,
      modelId: '',
      config: {}
    }
  },
  watch: {
    $route: function () {
      this.showPage = false;
      this.getListData();
    },
  },
  computed: {
    ...mapGetters(['visualDevData'])
  },
  created() {
    this.getListData();
    // let isPreview = this.$route.query.isPreview || false
    // if (isPreview) {
    //   this.modelId = this.$route.query.id
    //   this.isPreview = true
    // } else {
    //   this.modelId = this.$route.meta.relationId
    // }
    // const type = this.$route.query.previewType ? this.$route.query.previewType : ''
    // if (!this.modelId) return
    // this.getConfigData({ type })
  },
  methods: {
    getListData() {
      this.visualDevData.forEach((item) => {
        if (this.$route.path.indexOf(item.enCode) > -1) {
          this.modelId = item.id
          this.type = item.type
        }
      })
      this.getConfigData(this.type)
    },
    getConfigData(previewType) {
      getConfigData(this.modelId, previewType).then(res => {
        if (res.data.code !== 200 || !res.data.data) {
          this.$store.dispatch('tagsView/delView', this.$route)
          this.$router.replace('/404')
          this.$message({
            message: res.data.msg || '请求出错，请重试',
            type: 'error'
          });
          return
        }
        let data = res.data.data;
        this.config = data;
        if (res.data.data.webType == '1') {
          this.currentView = 'Form'
        } else {
          this.currentView = 'List'
        }
        this.showPage = true
      })
    }
  }
}
</script>
