var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "SNOWS-dialog SNOWS-dialog_center sign-dialog",
          attrs: {
            title: "请签名",
            closeOnClickModal: false,
            visible: _vm.signVisible,
            "append-to-body": "",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.signVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "sign-main-box" },
            [
              _c("vue-esign", {
                ref: "esign",
                attrs: { height: 300, width: 560, lineWidth: _vm.lineWidth },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showTip,
                      expression: "showTip",
                    },
                  ],
                  staticClass: "tip",
                },
                [_vm._v("使用鼠标在此签名")]
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleReset } }, [
                _vm._v("清空"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.handleGenerate()
                    },
                  },
                },
                [_vm._v("确定签名")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }