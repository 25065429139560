var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tabs",
    {
      staticClass: "recordSummary",
      staticStyle: { height: "100%" },
      attrs: { "tab-position": "left" },
      model: {
        value: _vm.activeTab,
        callback: function ($$v) {
          _vm.activeTab = $$v
        },
        expression: "activeTab",
      },
    },
    [
      _c("el-tab-pane", { attrs: { label: "按部门汇总", name: "1" } }),
      _c("el-tab-pane", { attrs: { label: "按岗位汇总", name: "3" } }),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "recordSummary-list",
        },
        [
          _vm.list.length
            ? _vm._l(_vm.list, function (item, i) {
                return _c(
                  "el-card",
                  { key: i, staticClass: "recordSummary-item" },
                  [
                    _c("div", { staticClass: "recordSummary-item-main" }, [
                      _c("div", { staticClass: "cap" }, [
                        _vm._v(_vm._s(item.fullName) + "意见"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "content" },
                        _vm._l(item.list, function (child, j) {
                          return _c(
                            "div",
                            { key: j, staticClass: "child-item" },
                            [
                              child.handleStatus == 0
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "child-item-block" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "avatar" },
                                          [
                                            _c("el-avatar", {
                                              attrs: {
                                                src:
                                                  _vm.define.comUrl +
                                                  child.headIcon,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "child-item-title" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "child-item-line",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "name" },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(child.userName)
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        " 于 " +
                                                          _vm._s(
                                                            _vm._f("toDate")(
                                                              child.handleTime
                                                            )
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "el-tag",
                                                  {
                                                    staticClass: "tag",
                                                    attrs: { type: "danger" },
                                                  },
                                                  [_vm._v("退回")]
                                                ),
                                              ],
                                              1
                                            ),
                                            child.handleOpinion
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "child-item-option",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          child.handleOpinion
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              child.handleStatus == 1
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "child-item-block" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "avatar" },
                                          [
                                            _c("el-avatar", {
                                              attrs: {
                                                src:
                                                  _vm.define.comUrl +
                                                  child.headIcon,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "child-item-title" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "child-item-line",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "name" },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(child.userName)
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        " 于 " +
                                                          _vm._s(
                                                            _vm._f("toDate")(
                                                              child.handleTime
                                                            )
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "el-tag",
                                                  {
                                                    staticClass: "tag",
                                                    attrs: { type: "success" },
                                                  },
                                                  [_vm._v("同意")]
                                                ),
                                              ],
                                              1
                                            ),
                                            child.handleOpinion
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "child-item-option",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          child.handleOpinion
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            child.fileList.length
                                              ? _c(
                                                  "div",
                                                  { staticClass: "file-List" },
                                                  [
                                                    _c("SNOWS-UploadFz", {
                                                      attrs: {
                                                        detailed: "",
                                                        disabled: "",
                                                        showIcon: false,
                                                      },
                                                      model: {
                                                        value: child.fileList,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            child,
                                                            "fileList",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "child.fileList",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              child.handleStatus == 2
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "child-item-block" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "avatar" },
                                          [
                                            _c("el-avatar", {
                                              attrs: {
                                                src:
                                                  _vm.define.comUrl +
                                                  child.headIcon,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "child-item-title" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "child-item-line",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "name" },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(child.userName)
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        " 于 " +
                                                          _vm._s(
                                                            _vm._f("toDate")(
                                                              child.handleTime
                                                            )
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "el-tag",
                                                  { staticClass: "tag" },
                                                  [_vm._v("发起")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              child.handleStatus == 3
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "child-item-block" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "avatar" },
                                          [
                                            _c("el-avatar", {
                                              attrs: {
                                                src:
                                                  _vm.define.comUrl +
                                                  child.headIcon,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "child-item-title" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "child-item-line",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "name" },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(child.userName)
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        " 于 " +
                                                          _vm._s(
                                                            _vm._f("toDate")(
                                                              child.handleTime
                                                            )
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "el-tag",
                                                  {
                                                    staticClass: "tag",
                                                    attrs: { type: "warning" },
                                                  },
                                                  [_vm._v("撤回")]
                                                ),
                                              ],
                                              1
                                            ),
                                            child.handleOpinion
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "child-item-option",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          child.handleOpinion
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              child.handleStatus == 4
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "child-item-block" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "avatar" },
                                          [
                                            _c("el-avatar", {
                                              attrs: {
                                                src:
                                                  _vm.define.comUrl +
                                                  child.headIcon,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "child-item-title" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "child-item-line",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "name" },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(child.userName)
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        " 于 " +
                                                          _vm._s(
                                                            _vm._f("toDate")(
                                                              child.handleTime
                                                            )
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "el-tag",
                                                  {
                                                    staticClass: "tag",
                                                    attrs: { type: "warning" },
                                                  },
                                                  [_vm._v("终止")]
                                                ),
                                              ],
                                              1
                                            ),
                                            child.handleOpinion
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "child-item-option",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          child.handleOpinion
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              child.handleStatus == 5
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "child-item-block" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "avatar" },
                                          [
                                            _c("el-avatar", {
                                              attrs: {
                                                src:
                                                  _vm.define.comUrl +
                                                  child.headIcon,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "child-item-title" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "child-item-line",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "name" },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(child.userName)
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        " 于 " +
                                                          _vm._s(
                                                            _vm._f("toDate")(
                                                              child.handleTime
                                                            )
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "el-tag",
                                                  { staticClass: "tag" },
                                                  [_vm._v("指派")]
                                                ),
                                              ],
                                              1
                                            ),
                                            child.handleOpinion
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "child-item-option",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          child.handleOpinion
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              child.handleStatus == 6
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "child-item-block" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "avatar" },
                                          [
                                            _c("el-avatar", {
                                              attrs: {
                                                src:
                                                  _vm.define.comUrl +
                                                  child.headIcon,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "child-item-title" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "child-item-line",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "name" },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(child.userName)
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        " 于 " +
                                                          _vm._s(
                                                            _vm._f("toDate")(
                                                              child.handleTime
                                                            )
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "el-tag",
                                                  { staticClass: "tag" },
                                                  [_vm._v("加签")]
                                                ),
                                              ],
                                              1
                                            ),
                                            child.handleOpinion
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "child-item-option",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          child.handleOpinion
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              child.handleStatus == 7
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "child-item-block" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "avatar" },
                                          [
                                            _c("el-avatar", {
                                              attrs: {
                                                src:
                                                  _vm.define.comUrl +
                                                  child.headIcon,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "child-item-title" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "child-item-line",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "name" },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(child.userName)
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        " 于 " +
                                                          _vm._s(
                                                            _vm._f("toDate")(
                                                              child.handleTime
                                                            )
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "el-tag",
                                                  { staticClass: "tag" },
                                                  [_vm._v("转审")]
                                                ),
                                              ],
                                              1
                                            ),
                                            child.handleOpinion
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "child-item-option",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          child.handleOpinion
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        }),
                        0
                      ),
                    ]),
                  ]
                )
              })
            : _c("el-empty", {
                attrs: { description: "暂无数据", "image-size": 120 },
              }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }