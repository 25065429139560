var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    {
      class: _vm.formConf.formStyle
        ? _vm.formConf.formStyle + " word-from-detail"
        : "",
      attrs: { gutter: _vm.formConf.gutter },
    },
    [
      _c(
        "el-form",
        {
          ref: _vm.formConf.formRef,
          attrs: {
            size: _vm.formConf.size,
            "label-position": _vm.formConf.labelPosition,
            "label-width": `${_vm.formConf.labelWidth}px`,
          },
        },
        _vm._l(_vm.formConf.fields, function (item, index) {
          return _c(
            "Item",
            _vm._g(
              _vm._b(
                { key: index, attrs: { item: item, formValue: _vm.formValue } },
                "Item",
                _vm.$props,
                false
              ),
              _vm.$listeners
            )
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }