var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.formData.popupType === "general"
        ? [
            _c(
              "el-dialog",
              {
                staticClass: "SNOWS-dialog SNOWS-dialog_center",
                attrs: {
                  title: "详情",
                  "close-on-click-modal": false,
                  visible: _vm.visible,
                  "lock-scroll": "",
                  width: _vm.formData.generalWidth,
                  "append-to-body": "",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.visible = $event
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading || _vm.mainLoading,
                        expression: "loading || mainLoading",
                      },
                    ],
                    staticClass: "dynamicDetail",
                  },
                  [
                    !_vm.loading
                      ? _c("Parser", {
                          attrs: {
                            formConf: _vm.formData,
                            relationData: _vm.relationData,
                            formValue: _vm.formValue,
                          },
                          on: { toDetail: _vm.toDetail },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _vm.formData.hasPrintBtn && _vm.formData.printId
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.printBrowseVisible = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.formData.printButtonText || "打 印"
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.visible = false
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                  ],
                  2
                ),
              ]
            ),
          ]
        : _vm._e(),
      _vm.formData.popupType === "fullScreen"
        ? [
            _c("transition", { attrs: { name: "el-zoom-in-center" } }, [
              _c("div", { staticClass: "SNOWS-preview-main" }, [
                _c(
                  "div",
                  { staticClass: "SNOWS-common-page-header" },
                  [
                    _c("el-page-header", {
                      attrs: { content: "详情" },
                      on: { back: _vm.goBack },
                    }),
                    _c(
                      "div",
                      { staticClass: "options" },
                      [
                        _vm.formData.hasPrintBtn && _vm.formData.printId
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.printBrowseVisible = true
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.formData.printButtonText || "打 印"
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                        _c("el-button", { on: { click: _vm.goBack } }, [
                          _vm._v("取消"),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading || _vm.mainLoading,
                        expression: "loading || mainLoading",
                      },
                    ],
                    staticClass: "dynamic-form-main dynamicDetail",
                    staticStyle: {
                      margin: "0 auto",
                      width: "calc(100% - 20px)",
                    },
                  },
                  [
                    !_vm.loading
                      ? _c("Parser", {
                          attrs: {
                            formConf: _vm.formData,
                            relationData: _vm.relationData,
                            formValue: _vm.formValue,
                          },
                          on: { toDetail: _vm.toDetail },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        : _vm._e(),
      _vm.formData.popupType === "drawer"
        ? [
            _c(
              "el-drawer",
              {
                staticClass: "SNOWS-common-drawer",
                attrs: {
                  title: "详情",
                  visible: _vm.visible,
                  wrapperClosable: false,
                  size: _vm.formData.drawerWidth,
                  "append-to-body": "",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.visible = $event
                  },
                },
              },
              [
                _c("div", { staticClass: "SNOWS-flex-main" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading || _vm.mainLoading,
                          expression: "loading || mainLoading",
                        },
                      ],
                      staticClass: "dynamicForm dynamicDetail",
                    },
                    [
                      !_vm.loading
                        ? _c("Parser", {
                            attrs: {
                              formConf: _vm.formData,
                              relationData: _vm.relationData,
                              formValue: _vm.formValue,
                            },
                            on: { toDetail: _vm.toDetail },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "drawer-footer" },
                    [
                      _vm.formData.hasPrintBtn && _vm.formData.printId
                        ? [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    _vm.printBrowseVisible = true
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.formData.printButtonText || "打 印"
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.visible = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                    ],
                    2
                  ),
                ]),
              ]
            ),
          ]
        : _vm._e(),
      _vm.detailVisible
        ? _c("Detail", {
            ref: "Detail",
            on: {
              close: function ($event) {
                _vm.detailVisible = false
              },
            },
          })
        : _vm._e(),
      _c("print-browse", {
        attrs: {
          visible: _vm.printBrowseVisible,
          id: _vm.formData.printId,
          formId: _vm.dataForm.id,
        },
        on: {
          "update:visible": function ($event) {
            _vm.printBrowseVisible = $event
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }