var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.formConf.popupType === "general"
        ? [
            _c(
              "el-dialog",
              {
                staticClass: "SNOWS-dialog SNOWS-dialog_center",
                attrs: {
                  title: _vm.formTitle,
                  "close-on-click-modal": false,
                  visible: _vm.visible,
                  "lock-scroll": "",
                  width: _vm.formConf.generalWidth,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.visible = $event
                  },
                },
              },
              [
                !_vm.loading
                  ? _c("parser", {
                      key: _vm.key,
                      ref: "dynamicForm",
                      attrs: { "form-conf": _vm.formConf },
                      on: { submit: _vm.submitForm },
                    })
                  : _vm._e(),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _vm.formConf.hasPrintBtn &&
                    _vm.formConf.printId &&
                    _vm.dataForm.id &&
                    false
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.print },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.formConf.printButtonText || "打 印"
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.visible = false
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.formConf.cancelButtonText || "取 消"))]
                    ),
                    !_vm.formConf.disabled
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", loading: _vm.btnLoading },
                            on: {
                              click: function ($event) {
                                return _vm.dataFormSubmit()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.formConf.confirmButtonText || "确 定"
                                )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.formConf.popupType === "fullScreen"
        ? [
            _c("transition", { attrs: { name: "el-zoom-in-center" } }, [
              _c("div", { staticClass: "SNOWS-preview-main" }, [
                _c(
                  "div",
                  { staticClass: "SNOWS-common-page-header" },
                  [
                    _c("el-page-header", {
                      attrs: { content: _vm.formTitle },
                      on: { back: _vm.goBack },
                    }),
                    _c(
                      "div",
                      { staticClass: "options" },
                      [
                        _vm.formConf.hasPrintBtn &&
                        _vm.formConf.printId &&
                        _vm.dataForm.id &&
                        false
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.print },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.formConf.printButtonText || "打 印"
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                        !_vm.formConf.disabled
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  loading: _vm.btnLoading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.dataFormSubmit()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.formConf.confirmButtonText || "确 定"
                                    ) +
                                    "\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("el-button", { on: { click: _vm.goBack } }, [
                          _vm._v(
                            _vm._s(_vm.formConf.cancelButtonText || "取 消")
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "dynamic-form-main",
                    staticStyle: {
                      margin: "0 auto",
                      width: "calc(100% - 20px)",
                    },
                  },
                  [
                    !_vm.loading
                      ? _c("parser", {
                          key: _vm.key,
                          ref: "dynamicForm",
                          attrs: { "form-conf": _vm.formConf },
                          on: { submit: _vm.submitForm },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        : _vm._e(),
      _vm.formConf.popupType === "drawer"
        ? [
            _c(
              "el-drawer",
              {
                staticClass: "SNOWS-common-drawer",
                attrs: {
                  title: _vm.formTitle,
                  visible: _vm.visible,
                  wrapperClosable: false,
                  size: _vm.formConf.drawerWidth,
                  "append-to-body": "",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.visible = $event
                  },
                },
              },
              [
                _c("div", { staticClass: "SNOWS-flex-main" }, [
                  _c(
                    "div",
                    { staticClass: "dynamicForm" },
                    [
                      !_vm.loading
                        ? _c("parser", {
                            key: _vm.key,
                            ref: "dynamicForm",
                            attrs: { "form-conf": _vm.formConf },
                            on: { submit: _vm.submitForm },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "drawer-footer" },
                    [
                      _vm.formConf.hasPrintBtn &&
                      _vm.formConf.printId &&
                      _vm.dataForm.id &&
                      false
                        ? [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.print },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.formConf.printButtonText || "打 印"
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.visible = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.formConf.cancelButtonText || "取 消")
                          ),
                        ]
                      ),
                      !_vm.formConf.disabled
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.btnLoading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.dataFormSubmit()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.formConf.confirmButtonText || "确 定"
                                  ) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]
            ),
          ]
        : _vm._e(),
      _c("print-browse", {
        attrs: {
          visible: _vm.printBrowseVisible,
          id: _vm.formConf.printId,
          formId: _vm.dataForm.id,
        },
        on: {
          "update:visible": function ($event) {
            _vm.printBrowseVisible = $event
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }