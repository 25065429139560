var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          staticClass: "SNOWS-dialog SNOWS-dialog_center form-script-dialog",
          attrs: {
            title: "提交审核",
            "close-on-click-modal": false,
            "lock-scroll": "",
            "append-to-body": "",
            width: "600px",
            "modal-append-to-body": false,
          },
          on: { open: _vm.onOpen },
        },
        "el-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "el-form",
        {
          ref: "candidateForm",
          attrs: {
            model: _vm.candidateForm,
            "label-width":
              _vm.candidateForm.candidateList.length || _vm.branchList.length
                ? "130px"
                : "80px",
          },
        },
        [
          _vm.branchList && _vm.branchList.length
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "分支选择",
                    prop: "branchList",
                    rules: [
                      {
                        required: true,
                        message: `分支不能为空`,
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        placeholder: "请选择审批分支",
                        clearable: "",
                      },
                      on: { change: _vm.onBranchChange },
                      model: {
                        value: _vm.candidateForm.branchList,
                        callback: function ($$v) {
                          _vm.$set(_vm.candidateForm, "branchList", $$v)
                        },
                        expression: "candidateForm.branchList",
                      },
                    },
                    _vm._l(_vm.branchList, function (item) {
                      return _c("el-option", {
                        key: item.nodeId,
                        attrs: { label: item.nodeName, value: item.nodeId },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.candidateForm.candidateList, function (item, i) {
            return _c(
              "el-form-item",
              {
                key: i,
                attrs: {
                  label: item.nodeName + item.label,
                  prop: "candidateList." + i + ".value",
                  rules: item.rules,
                },
              },
              [
                item.hasCandidates
                  ? _c("candidate-user-select", {
                      attrs: {
                        multiple: "",
                        placeholder: "请选择" + item.label,
                        taskId: _vm.taskId,
                        formData: _vm.formData,
                        nodeId: item.nodeId,
                      },
                      model: {
                        value: item.value,
                        callback: function ($$v) {
                          _vm.$set(item, "value", $$v)
                        },
                        expression: "item.value",
                      },
                    })
                  : _c("user-select", {
                      attrs: {
                        multiple: "",
                        placeholder: "请选择" + item.label,
                        title: "候选人员",
                      },
                      model: {
                        value: item.value,
                        callback: function ($$v) {
                          _vm.$set(item, "value", $$v)
                        },
                        expression: "item.value",
                      },
                    }),
              ],
              1
            )
          }),
          _vm.isCustomCopy
            ? _c(
                "el-form-item",
                { attrs: { label: "抄送人员" } },
                [
                  _c("user-select", {
                    attrs: { placeholder: "请选择", multiple: "" },
                    model: {
                      value: _vm.candidateForm.copyIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.candidateForm, "copyIds", $$v)
                      },
                      expression: "candidateForm.copyIds",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnLoading },
              on: {
                click: function ($event) {
                  return _vm.submitCandidate()
                },
              },
            },
            [_vm._v("\n      确定\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }