var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SNOWS-common-layout" },
    [
      _c(
        "SNOWS-table",
        {
          staticClass: "recordListTable",
          attrs: { data: _vm.list, hasNO: false, height: "100%" },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "nodeName",
              label: "节点名称",
              "show-overflow-tooltip": "",
              width: "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.handelNodeDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          " + _vm._s(scope.row.nodeName))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "userName", label: "操作人员", width: "150" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "creatorTime",
              label: "接收时间",
              width: "150",
              formatter: _vm.sinoma.tableDateFormat,
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "handleTime",
              label: "操作时间",
              width: "150",
              formatter: _vm.sinoma.tableDateFormat,
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "handleStatus",
              label: "执行动作",
              "min-width": "130",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "item" }, [
                      _c("span", {
                        style: {
                          background:
                            _vm.colorList[scope.row.handleStatus || 0],
                        },
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.statusList[scope.row.handleStatus || 0]) +
                          "\n          "
                      ),
                      scope.row.handleStatus == 5 ||
                      scope.row.handleStatus == 6 ||
                      scope.row.handleStatus == 7 ||
                      scope.row.handleStatus == 10
                        ? _c("div", [
                            _vm._v(
                              "\n            ：" +
                                _vm._s(scope.row.operatorId) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "signImg",
              label: "签名",
              width: "120",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.signImg
                      ? _c("el-image", {
                          staticStyle: { width: "80px" },
                          attrs: {
                            src: scope.row.signImg,
                            "preview-src-list": [scope.row.signImg],
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "fileList",
              label: "附件",
              width: "150",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(
                    JSON.parse(scope.row.fileList),
                    function (file, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          on: {
                            click: function ($event) {
                              return _vm.handlePreview(file)
                            },
                          },
                        },
                        [
                          _c(
                            "el-link",
                            { attrs: { type: "primary", underline: false } },
                            [_vm._v(_vm._s(file.name))]
                          ),
                        ],
                        1
                      )
                    }
                  )
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "handleOpinion",
              label: "备注",
              width: "200",
              "show-overflow-tooltip": "",
            },
          }),
        ],
        1
      ),
      _c("Preview", {
        attrs: {
          visible: _vm.previewVisible,
          file: _vm.activeFile,
          showDownload: true,
        },
        on: {
          "update:visible": function ($event) {
            _vm.previewVisible = $event
          },
        },
      }),
      _vm.formVisible
        ? _c("FormBox", {
            ref: "FormBox",
            on: {
              close: function ($event) {
                _vm.formVisible = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }