var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          staticClass: "SNOWS-dialog SNOWS-dialog_center",
          attrs: {
            title: "异常处理",
            "close-on-click-modal": false,
            "lock-scroll": "",
            "append-to-body": "",
            width: "600px",
            "modal-append-to-body": false,
          },
          on: { open: _vm.onOpen },
        },
        "el-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: { "label-width": "100px", model: _vm.dataForm },
        },
        _vm._l(_vm.dataForm.nodeList, function (item, i) {
          return _c(
            "el-form-item",
            {
              key: i,
              attrs: {
                label: item.nodeName,
                prop: "nodeList." + i + ".value",
                rules: item.rules,
              },
            },
            [
              _c("userSelect", {
                attrs: { multiple: "", placeholder: "请选择异常处理人员" },
                model: {
                  value: item.value,
                  callback: function ($$v) {
                    _vm.$set(item, "value", $$v)
                  },
                  expression: "item.value",
                },
              }),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [_vm._v("确定\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }