var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SNOWS-common-layout" },
    [
      _vm.config.enableFlow
        ? _c("FlowBox", { ref: "FlowBox", on: { close: _vm.closeFlow } })
        : _c("div", { staticClass: "SNOWS-preview-main" }, [
            _c("div", { staticClass: "SNOWS-common-page-header" }, [
              _c("p", [_vm._v(_vm._s(_vm.config.fullName))]),
              _c(
                "div",
                { staticClass: "options" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btnLoading },
                      on: {
                        click: function ($event) {
                          return _vm.dataFormSubmit()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.formConf.confirmButtonText || "确 定")
                      ),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning" },
                      on: {
                        click: function ($event) {
                          return _vm.resetForm()
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "dynamic-form-main",
                staticStyle: { margin: "0 auto", width: "calc(100% - 20px)" },
              },
              [
                !_vm.loading
                  ? _c("parser", {
                      key: _vm.key,
                      ref: "dynamicForm",
                      attrs: { "form-conf": _vm.formConf },
                      on: { submit: _vm.submitForm },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }