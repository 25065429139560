var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SNOWS-common-layout" },
    [
      _vm.columnData.type === 2
        ? _c(
            "div",
            { staticClass: "SNOWS-common-layout-left" },
            [
              _vm.columnData.treeTitle
                ? _c("div", { staticClass: "SNOWS-common-title" }, [
                    _c("h2", [_vm._v(_vm._s(_vm.columnData.treeTitle))]),
                  ])
                : _vm._e(),
              _c("el-tree", {
                ref: "treeBox",
                staticClass: "SNOWS-common-el-tree",
                attrs: {
                  data: _vm.treeData,
                  props: _vm.treeProps,
                  "default-expand-all": "",
                  "highlight-current": "",
                  "expand-on-click-node": false,
                  "node-key": _vm.treeProps.value,
                },
                on: { "node-click": _vm.handleNodeClick },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ node, data }) {
                        return _c("span", { staticClass: "custom-tree-node" }, [
                          _c("i", { class: data.icon }),
                          _c("span", { staticClass: "text" }, [
                            _vm._v(_vm._s(node.label)),
                          ]),
                        ])
                      },
                    },
                  ],
                  null,
                  false,
                  1575034392
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "SNOWS-common-layout-center" },
        [
          _c("Search", {
            ref: "Search",
            attrs: { list: _vm.columnData.searchList },
            on: { reset: _vm.reset, search: _vm.searchData },
          }),
          _c(
            "div",
            { staticClass: "SNOWS-common-layout-main SNOWS-flex-main" },
            [
              _c("div", { staticClass: "SNOWS-common-head" }, [
                _vm.isPreview || !_vm.columnData.useBtnPermission
                  ? _c(
                      "div",
                      _vm._l(_vm.columnData.btnsList, function (item, i) {
                        return _c(
                          "el-button",
                          {
                            key: i,
                            attrs: {
                              type: i == 0 ? "primary" : "text",
                              icon: item.icon,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.headBtnsHandel(item.value)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.label) +
                                "\n          "
                            ),
                          ]
                        )
                      }),
                      1
                    )
                  : _c(
                      "div",
                      _vm._l(_vm.columnData.btnsList, function (item, i) {
                        return _vm.addShow
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "has",
                                    rawName: "v-has",
                                    value: "btn_" + item.value,
                                    expression: "'btn_' + item.value",
                                  },
                                ],
                                key: i,
                                attrs: {
                                  type: i == 0 ? "primary" : "text",
                                  icon: item.icon,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.headBtnsHandel(item.value)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.label) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e()
                      }),
                      1
                    ),
                _c(
                  "div",
                  { staticClass: "SNOWS-common-head-right" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          effect: "dark",
                          content: "刷新",
                          placement: "top",
                        },
                      },
                      [
                        _c("el-link", {
                          attrs: {
                            icon: "icon-ym icon-ym-Refresh SNOWS-common-head-icon",
                            underline: false,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.initData()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm.refreshTable
                ? _c(
                    "SNOWS-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      ref: "tableRef",
                      attrs: {
                        data: _vm.list,
                        "row-key": "id",
                        size: "medium",
                        "default-expand-all":
                          _vm.columnData.childTableStyle !== 2,
                        "tree-props": { children: "children", hasChildren: "" },
                        "row-style": _vm.rowStyle,
                        "cell-style": _vm.cellStyle,
                        "has-c": _vm.hasBatchBtn,
                        "custom-column": "",
                        "span-method": _vm.arraySpanMethod,
                        hasNO: _vm.columnData.childTableStyle !== 2,
                        hasNOFixed: _vm.columnList.some(
                          (o) => o.fixed == "left"
                        ),
                      },
                      on: {
                        "sort-change": _vm.sortChange,
                        "selection-change": _vm.handleSelectionChange,
                      },
                    },
                    [
                      _vm.columnData.type === 4
                        ? [
                            _vm._l(_vm.columnList, function (item, i) {
                              return [
                                item.jnpfKey !== "table"
                                  ? _c("el-table-column", {
                                      key: i,
                                      attrs: {
                                        prop: item.prop,
                                        label: item.label,
                                        align: item.align,
                                        fixed:
                                          item.fixed != "none"
                                            ? item.fixed
                                            : false,
                                        width: item.width,
                                        sortable: item.sortable
                                          ? "custom"
                                          : item.sortable,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                scope.row.rowEdit
                                                  ? [
                                                      item.jnpfKey ===
                                                      "numInput"
                                                        ? [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  placeholder:
                                                                    item.placeholder,
                                                                  min: item.min,
                                                                  max: item.max,
                                                                  step: item.step,
                                                                  precision:
                                                                    item.precision,
                                                                  "controls-position":
                                                                    item[
                                                                      "controls-position"
                                                                    ],
                                                                  disabled:
                                                                    item.disabled,
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row[
                                                                      item.prop
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        item.prop,
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row[item.prop]",
                                                                },
                                                              }
                                                            ),
                                                          ]
                                                        : [
                                                            "rate",
                                                            "slider",
                                                          ].includes(
                                                            item.jnpfKey
                                                          )
                                                        ? [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  placeholder:
                                                                    "请输入",
                                                                  "controls-position":
                                                                    "right",
                                                                  disabled:
                                                                    item.disabled,
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row[
                                                                      item.prop
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        item.prop,
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row[item.prop]",
                                                                },
                                                              }
                                                            ),
                                                          ]
                                                        : item.jnpfKey ===
                                                          "switch"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "padding-top":
                                                                  "5px",
                                                              },
                                                            },
                                                            [
                                                              _c("el-switch", {
                                                                attrs: {
                                                                  "active-value":
                                                                    item[
                                                                      "active-value"
                                                                    ],
                                                                  "inactive-value":
                                                                    item[
                                                                      "inactive-value"
                                                                    ],
                                                                  disabled:
                                                                    item.disabled,
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row[
                                                                      item.prop
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        item.prop,
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row[item.prop]",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          )
                                                        : item.jnpfKey ===
                                                          "time"
                                                        ? [
                                                            _c(
                                                              "el-time-picker",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  "picker-options":
                                                                    item[
                                                                      "picker-options"
                                                                    ],
                                                                  placeholder:
                                                                    item.placeholder,
                                                                  clearable:
                                                                    item.clearable,
                                                                  "value-format":
                                                                    item[
                                                                      "value-format"
                                                                    ],
                                                                  format:
                                                                    item.format,
                                                                  readonly:
                                                                    item.readonly,
                                                                  disabled:
                                                                    item.disabled,
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row[
                                                                      item.prop
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        item.prop,
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row[item.prop]",
                                                                },
                                                              }
                                                            ),
                                                          ]
                                                        : ["date"].includes(
                                                            item.jnpfKey
                                                          )
                                                        ? [
                                                            _c(
                                                              "el-date-picker",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  type:
                                                                    item.type ||
                                                                    "datetime",
                                                                  clearable:
                                                                    item.clearable,
                                                                  placeholder:
                                                                    item.placeholder,
                                                                  "value-format":
                                                                    "timestamp",
                                                                  format:
                                                                    item.format ||
                                                                    "yyyy-MM-dd HH:mm:ss",
                                                                  readonly:
                                                                    item.readonly,
                                                                  disabled:
                                                                    item.disabled,
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row[
                                                                      item.prop
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        item.prop,
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row[item.prop]",
                                                                },
                                                              }
                                                            ),
                                                          ]
                                                        : [
                                                            "comSelect",
                                                          ].includes(
                                                            item.jnpfKey
                                                          )
                                                        ? [
                                                            _c("comSelect", {
                                                              attrs: {
                                                                placeholder:
                                                                  item.placeholder,
                                                                multiple:
                                                                  item.multiple,
                                                                clearable:
                                                                  item.clearable,
                                                                disabled:
                                                                  item.disabled,
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row[
                                                                    item.prop
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      item.prop,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row[item.prop]",
                                                              },
                                                            }),
                                                          ]
                                                        : [
                                                            "depSelect",
                                                          ].includes(
                                                            item.jnpfKey
                                                          )
                                                        ? [
                                                            _c("depSelect", {
                                                              attrs: {
                                                                placeholder:
                                                                  item.placeholder,
                                                                selectType:
                                                                  item.selectType,
                                                                ableDepIds:
                                                                  item.ableDepIds,
                                                                multiple:
                                                                  item.multiple,
                                                                clearable:
                                                                  item.clearable,
                                                                disabled:
                                                                  item.disabled,
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row[
                                                                    item.prop
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      item.prop,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row[item.prop]",
                                                              },
                                                            }),
                                                          ]
                                                        : [
                                                            "userSelect",
                                                          ].includes(
                                                            item.jnpfKey
                                                          )
                                                        ? [
                                                            _c("userSelect", {
                                                              attrs: {
                                                                placeholder:
                                                                  item.placeholder,
                                                                selectType:
                                                                  item.selectType,
                                                                ableDepIds:
                                                                  item.ableDepIds,
                                                                ablePosIds:
                                                                  item.ablePosIds,
                                                                ableUserIds:
                                                                  item.ableUserIds,
                                                                ableRoleIds:
                                                                  item.ableRoleIds,
                                                                ableGroupIds:
                                                                  item.ableGroupIds,
                                                                multiple:
                                                                  item.multiple,
                                                                clearable:
                                                                  item.clearable,
                                                                disabled:
                                                                  item.disabled,
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row[
                                                                    item.prop
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      item.prop,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row[item.prop]",
                                                              },
                                                            }),
                                                          ]
                                                        : [
                                                            "usersSelect",
                                                          ].includes(
                                                            item.jnpfKey
                                                          )
                                                        ? [
                                                            _c("usersSelect", {
                                                              attrs: {
                                                                placeholder:
                                                                  item.placeholder,
                                                                selectType:
                                                                  item.selectType,
                                                                ableIds:
                                                                  item.ableIds,
                                                                multiple:
                                                                  item.multiple,
                                                                clearable:
                                                                  item.clearable,
                                                                disabled:
                                                                  item.disabled,
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row[
                                                                    item.prop
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      item.prop,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row[item.prop]",
                                                              },
                                                            }),
                                                          ]
                                                        : [
                                                            "posSelect",
                                                          ].includes(
                                                            item.jnpfKey
                                                          )
                                                        ? [
                                                            _c("posSelect", {
                                                              attrs: {
                                                                placeholder:
                                                                  item.placeholder,
                                                                selectType:
                                                                  item.selectType,
                                                                ableDepIds:
                                                                  item.ableDepIds,
                                                                ablePosIds:
                                                                  item.ablePosIds,
                                                                multiple:
                                                                  item.multiple,
                                                                clearable:
                                                                  item.clearable,
                                                                disabled:
                                                                  item.disabled,
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row[
                                                                    item.prop
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      item.prop,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row[item.prop]",
                                                              },
                                                            }),
                                                          ]
                                                        : item.jnpfKey ===
                                                          "groupSelect"
                                                        ? [
                                                            _c("groupSelect", {
                                                              attrs: {
                                                                placeholder:
                                                                  item.placeholder,
                                                                multiple:
                                                                  item.multiple,
                                                                clearable:
                                                                  item.clearable,
                                                                disabled:
                                                                  item.disabled,
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row[
                                                                    item.prop
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      item.prop,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row[item.prop]",
                                                              },
                                                            }),
                                                          ]
                                                        : item.jnpfKey ===
                                                          "roleSelect"
                                                        ? [
                                                            _c("roleSelect", {
                                                              attrs: {
                                                                placeholder:
                                                                  item.placeholder,
                                                                multiple:
                                                                  item.multiple,
                                                                clearable:
                                                                  item.clearable,
                                                                disabled:
                                                                  item.disabled,
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row[
                                                                    item.prop
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      item.prop,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row[item.prop]",
                                                              },
                                                            }),
                                                          ]
                                                        : item.jnpfKey ===
                                                          "address"
                                                        ? [
                                                            _c("SNOWSAddress", {
                                                              attrs: {
                                                                level:
                                                                  item.level,
                                                                placeholder:
                                                                  item.placeholder,
                                                                multiple:
                                                                  item.multiple,
                                                                clearable:
                                                                  item.clearable,
                                                                disabled:
                                                                  item.disabled,
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row[
                                                                    item.prop
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      item.prop,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row[item.prop]",
                                                              },
                                                            }),
                                                          ]
                                                        : [
                                                            "select",
                                                            "radio",
                                                            "checkbox",
                                                          ].includes(
                                                            item.jnpfKey
                                                          )
                                                        ? [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  placeholder:
                                                                    item.placeholder,
                                                                  filterable:
                                                                    item.filterable,
                                                                  multiple:
                                                                    item.multiple ||
                                                                    item.jnpfKey ===
                                                                      "checkbox",
                                                                  clearable:
                                                                    item.clearable,
                                                                  disabled:
                                                                    item.disabled,
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row[
                                                                      item.prop
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        item.prop,
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row[item.prop]",
                                                                },
                                                              },
                                                              _vm._l(
                                                                item.__slot__
                                                                  .options,
                                                                function (
                                                                  oItem,
                                                                  i
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: i,
                                                                      attrs: {
                                                                        label:
                                                                          oItem[
                                                                            item
                                                                              .__config__
                                                                              .props
                                                                              .label
                                                                          ],
                                                                        value:
                                                                          oItem[
                                                                            item
                                                                              .__config__
                                                                              .props
                                                                              .value
                                                                          ],
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ]
                                                        : item.jnpfKey ===
                                                          "cascader"
                                                        ? [
                                                            _c("el-cascader", {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                options:
                                                                  item.options,
                                                                clearable:
                                                                  item.clearable,
                                                                "show-all-levels":
                                                                  item[
                                                                    "show-all-levels"
                                                                  ],
                                                                props:
                                                                  item.props
                                                                    .props,
                                                                filterable:
                                                                  item.filterable,
                                                                separator:
                                                                  item.separator,
                                                                placeholder:
                                                                  item.placeholder,
                                                                disabled:
                                                                  item.disabled,
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row[
                                                                    item.prop
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      item.prop,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row[item.prop]",
                                                              },
                                                            }),
                                                          ]
                                                        : item.jnpfKey ===
                                                          "treeSelect"
                                                        ? [
                                                            _c(
                                                              "SNOWS-TreeSelect",
                                                              {
                                                                attrs: {
                                                                  options:
                                                                    item.options,
                                                                  props:
                                                                    item.props
                                                                      .props,
                                                                  placeholder:
                                                                    item.placeholder,
                                                                  multiple:
                                                                    item.multiple,
                                                                  clearable:
                                                                    item.clearable,
                                                                  disabled:
                                                                    item.disabled,
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row[
                                                                      item.prop
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        item.prop,
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row[item.prop]",
                                                                },
                                                              }
                                                            ),
                                                          ]
                                                        : item.jnpfKey ===
                                                          "relationForm"
                                                        ? [
                                                            _c("relationForm", {
                                                              attrs: {
                                                                placeholder:
                                                                  item.placeholder,
                                                                modelId:
                                                                  item.modelId,
                                                                columnOptions:
                                                                  item.columnOptions,
                                                                relationField:
                                                                  item.relationField,
                                                                hasPage:
                                                                  item.hasPage,
                                                                pageSize:
                                                                  item.pageSize,
                                                                clearable:
                                                                  item.clearable,
                                                                disabled:
                                                                  item.disabled,
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row[
                                                                    item.prop
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      item.prop,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row[item.prop]",
                                                              },
                                                            }),
                                                          ]
                                                        : item.jnpfKey ===
                                                          "popupSelect"
                                                        ? [
                                                            _c("popupSelect", {
                                                              attrs: {
                                                                placeholder:
                                                                  item.placeholder,
                                                                interfaceId:
                                                                  item.interfaceId,
                                                                columnOptions:
                                                                  item.columnOptions,
                                                                propsValue:
                                                                  item.propsValue,
                                                                relationField:
                                                                  item.relationField,
                                                                hasPage:
                                                                  item.hasPage,
                                                                pageSize:
                                                                  item.pageSize,
                                                                popupType:
                                                                  item.popupType,
                                                                popupTitle:
                                                                  item.popupTitle,
                                                                popupWidth:
                                                                  item.popupWidth,
                                                                clearable:
                                                                  item.clearable,
                                                                disabled:
                                                                  item.disabled,
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row[
                                                                    item.prop
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      item.prop,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row[item.prop]",
                                                              },
                                                            }),
                                                          ]
                                                        : item.jnpfKey ===
                                                          "popupTableSelect"
                                                        ? [
                                                            _c(
                                                              "popupTableSelect",
                                                              {
                                                                attrs: {
                                                                  placeholder:
                                                                    item.placeholder,
                                                                  interfaceId:
                                                                    item.interfaceId,
                                                                  columnOptions:
                                                                    item.columnOptions,
                                                                  propsValue:
                                                                    item.propsValue,
                                                                  relationField:
                                                                    item.relationField,
                                                                  hasPage:
                                                                    item.hasPage,
                                                                  pageSize:
                                                                    item.pageSize,
                                                                  popupType:
                                                                    item.popupType,
                                                                  popupTitle:
                                                                    item.popupTitle,
                                                                  popupWidth:
                                                                    item.popupWidth,
                                                                  filterable:
                                                                    item.filterable,
                                                                  multiple:
                                                                    item.multiple,
                                                                  clearable:
                                                                    item.clearable,
                                                                  disabled:
                                                                    item.disabled,
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row[
                                                                      item.prop
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        item.prop,
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row[item.prop]",
                                                                },
                                                              }
                                                            ),
                                                          ]
                                                        : [
                                                            "comInput",
                                                            "textarea",
                                                          ].includes(
                                                            item.jnpfKey
                                                          )
                                                        ? [
                                                            _c(
                                                              "el-input",
                                                              {
                                                                attrs: {
                                                                  placeholder:
                                                                    item.placeholder,
                                                                  readonly:
                                                                    item.readonly,
                                                                  "prefix-icon":
                                                                    item[
                                                                      "prefix-icon"
                                                                    ],
                                                                  "suffix-icon":
                                                                    item[
                                                                      "suffix-icon"
                                                                    ],
                                                                  clearable:
                                                                    item.clearable,
                                                                  "show-word-limit":
                                                                    item[
                                                                      "show-word-limit"
                                                                    ],
                                                                  maxlength:
                                                                    item.maxlength,
                                                                  "show-password":
                                                                    item[
                                                                      "show-password"
                                                                    ],
                                                                  disabled:
                                                                    item.disabled,
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row[
                                                                      item.prop
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        item.prop,
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row[item.prop]",
                                                                },
                                                              },
                                                              [
                                                                item.__slot__ &&
                                                                item.__slot__
                                                                  .prepend
                                                                  ? _c(
                                                                      "template",
                                                                      {
                                                                        slot: "prepend",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item
                                                                              .__slot__
                                                                              .prepend
                                                                          ) +
                                                                            "\n                      "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                item.__slot__ &&
                                                                item.__slot__
                                                                  .append
                                                                  ? _c(
                                                                      "template",
                                                                      {
                                                                        slot: "append",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item
                                                                              .__slot__
                                                                              .append
                                                                          ) +
                                                                            "\n                      "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              2
                                                            ),
                                                          ]
                                                        : _vm.systemComponentsList.includes(
                                                            item.jnpfKey
                                                          )
                                                        ? [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  scope.row[
                                                                    item.prop +
                                                                      "_name"
                                                                  ] ||
                                                                    scope.row[
                                                                      item.prop
                                                                    ]
                                                                ) +
                                                                "\n                  "
                                                            ),
                                                          ]
                                                        : [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  scope.row[
                                                                    item.prop
                                                                  ]
                                                                ) +
                                                                "\n                  "
                                                            ),
                                                          ],
                                                    ]
                                                  : [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            scope.row[
                                                              item.prop +
                                                                "_name"
                                                            ] ||
                                                              scope.row[
                                                                item.prop
                                                              ]
                                                          ) +
                                                          "\n                "
                                                      ),
                                                    ],
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e(),
                              ]
                            }),
                          ]
                        : [
                            _vm.columnData.childTableStyle == 2 &&
                            _vm.childColumnList.length
                              ? [
                                  _c("el-table-column", {
                                    attrs: { width: "0" },
                                  }),
                                  _c("el-table-column", {
                                    attrs: { type: "expand", width: "40" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-tabs",
                                                _vm._l(
                                                  _vm.childColumnList,
                                                  function (child, cIndex) {
                                                    return _c(
                                                      "el-tab-pane",
                                                      {
                                                        key: cIndex,
                                                        attrs: {
                                                          label: child.label,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-table",
                                                          {
                                                            attrs: {
                                                              data: scope.row[
                                                                child.prop
                                                              ],
                                                              size: "mini",
                                                            },
                                                          },
                                                          _vm._l(
                                                            child.children,
                                                            function (
                                                              childTable,
                                                              iii
                                                            ) {
                                                              return _c(
                                                                "el-table-column",
                                                                {
                                                                  key: iii,
                                                                  attrs: {
                                                                    prop: childTable.vModel,
                                                                    label:
                                                                      childTable.childLabel,
                                                                    align:
                                                                      childTable.align,
                                                                    width:
                                                                      childTable.width,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3124616230
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      type: "index",
                                      width: "50",
                                      label: "序号",
                                      align: "center",
                                    },
                                  }),
                                ]
                              : _vm._e(),
                            _vm._l(_vm.columnList, function (item, i) {
                              return [
                                item.jnpfKey === "table"
                                  ? [
                                      _vm.columnData.childTableStyle != 2
                                        ? _c(
                                            "el-table-column",
                                            {
                                              key: i,
                                              attrs: {
                                                prop: item.prop,
                                                label: item.label,
                                                align: item.align,
                                              },
                                            },
                                            _vm._l(
                                              item.children,
                                              function (child, ii) {
                                                return _c("el-table-column", {
                                                  key: ii,
                                                  attrs: {
                                                    prop: child.prop,
                                                    label: child.childLabel,
                                                    align: child.align,
                                                    width: child.width,
                                                    sortable: child.sortable
                                                      ? "custom"
                                                      : child.sortable,
                                                    "class-name":
                                                      "child-table-box",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (scope) {
                                                          return [
                                                            !ii
                                                              ? _c(
                                                                  "child-table-column",
                                                                  {
                                                                    attrs: {
                                                                      data: scope
                                                                        .row[
                                                                        item
                                                                          .prop
                                                                      ],
                                                                      head: item.children,
                                                                      expand:
                                                                        scope
                                                                          .row[
                                                                          `${item.prop}Expand`
                                                                        ],
                                                                    },
                                                                    on: {
                                                                      toggleExpand:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.toggleExpand(
                                                                            scope.row,
                                                                            `${item.prop}Expand`
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  : _c("el-table-column", {
                                      key: i,
                                      attrs: {
                                        prop: item.prop,
                                        label: item.label,
                                        align: item.align,
                                        fixed:
                                          _vm.columnList.some(
                                            (o) => o.fixed == "left"
                                          ) &&
                                          i == 0 &&
                                          _vm.columnData.groupField &&
                                          _vm.columnData.type == 3
                                            ? "left"
                                            : item.fixed != "none" &&
                                              _vm.columnData.childTableStyle !=
                                                2
                                            ? item.fixed
                                            : false,
                                        width: item.width,
                                        sortable: item.sortable
                                          ? "custom"
                                          : item.sortable,
                                      },
                                    }),
                              ]
                            }),
                          ],
                      _vm.config.enableFlow == 1
                        ? _c("el-table-column", {
                            attrs: {
                              fixed:
                                _vm.columnList.some(
                                  (o) => o.fixed == "right"
                                ) && _vm.columnData.childTableStyle != 2
                                  ? "right"
                                  : false,
                              prop: "flowState",
                              label: "状态",
                              width: "100",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return !scope.row.top
                                      ? [
                                          scope.row.flowState == 1
                                            ? _c("el-tag", [_vm._v("等待审核")])
                                            : scope.row.flowState == 2
                                            ? _c(
                                                "el-tag",
                                                { attrs: { type: "success" } },
                                                [
                                                  _vm._v(
                                                    "审核通过\n            "
                                                  ),
                                                ]
                                              )
                                            : scope.row.flowState == 3
                                            ? _c(
                                                "el-tag",
                                                { attrs: { type: "danger" } },
                                                [
                                                  _vm._v(
                                                    "审核退回\n            "
                                                  ),
                                                ]
                                              )
                                            : scope.row.flowState == 4
                                            ? _c(
                                                "el-tag",
                                                { attrs: { type: "info" } },
                                                [
                                                  _vm._v(
                                                    "流程撤回\n            "
                                                  ),
                                                ]
                                              )
                                            : scope.row.flowState == 5
                                            ? _c(
                                                "el-tag",
                                                { attrs: { type: "info" } },
                                                [
                                                  _vm._v(
                                                    "审核终止\n            "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "el-tag",
                                                { attrs: { type: "warning" } },
                                                [_vm._v("等待提交")]
                                              ),
                                        ]
                                      : undefined
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        : _vm._e(),
                      _vm.columnBtnsList.length || _vm.customBtnsList.length
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              fixed:
                                _vm.columnData.childTableStyle == 2 &&
                                _vm.childColumnList.length
                                  ? false
                                  : "right",
                              width: _vm.operationWidth,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return !scope.row.top
                                      ? [
                                          scope.row.rowEdit
                                            ? [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      size: "mini",
                                                      type: "text",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.saveForRowEdit(
                                                          scope.row,
                                                          1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                保存\n              "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass:
                                                      "SNOWS-table-delBtn",
                                                    attrs: {
                                                      size: "mini",
                                                      type: "text",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.cancelRowEdit(
                                                          scope.row,
                                                          scope.$index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "取消\n              "
                                                    ),
                                                  ]
                                                ),
                                                _vm.config.enableFlow == 1
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          size: "mini",
                                                          type: "text",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.submitForRowEdit(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "提交\n              "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            : [
                                                _vm.isPreview ||
                                                !_vm.columnData.useBtnPermission
                                                  ? [
                                                      _vm._l(
                                                        _vm.columnBtnsList,
                                                        function (item, i) {
                                                          return [
                                                            item.value == "edit"
                                                              ? [
                                                                  _vm.columnData
                                                                    .type === 4
                                                                    ? [
                                                                        _c(
                                                                          "el-button",
                                                                          {
                                                                            key: i,
                                                                            attrs:
                                                                              {
                                                                                size: "mini",
                                                                                type: "text",
                                                                                disabled:
                                                                                  _vm
                                                                                    .config
                                                                                    .enableFlow ==
                                                                                    1 &&
                                                                                  [
                                                                                    1,
                                                                                    2,
                                                                                    4,
                                                                                    5,
                                                                                  ].indexOf(
                                                                                    scope
                                                                                      .row
                                                                                      .flowState
                                                                                  ) >
                                                                                    -1,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.hanLinEdit(
                                                                                    scope.row
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  item.label
                                                                                ) +
                                                                                "\n                      "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    : [
                                                                        _c(
                                                                          "el-button",
                                                                          {
                                                                            key: i,
                                                                            attrs:
                                                                              {
                                                                                size: "mini",
                                                                                type: "text",
                                                                                disabled:
                                                                                  _vm
                                                                                    .config
                                                                                    .enableFlow ==
                                                                                    1 &&
                                                                                  [
                                                                                    1,
                                                                                    2,
                                                                                    4,
                                                                                    5,
                                                                                  ].indexOf(
                                                                                    scope
                                                                                      .row
                                                                                      .flowState
                                                                                  ) >
                                                                                    -1,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.columnBtnsHandel(
                                                                                    item.value,
                                                                                    scope.row
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  item.label
                                                                                ) +
                                                                                "\n                      "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                ]
                                                              : item.value ==
                                                                "remove"
                                                              ? [
                                                                  _c(
                                                                    "el-button",
                                                                    {
                                                                      key: i,
                                                                      staticClass:
                                                                        "SNOWS-table-delBtn",
                                                                      attrs: {
                                                                        size: "mini",
                                                                        type: "text",
                                                                        disabled:
                                                                          _vm
                                                                            .config
                                                                            .enableFlow ==
                                                                            1 &&
                                                                          [
                                                                            1,
                                                                            2,
                                                                            3,
                                                                            5,
                                                                          ].indexOf(
                                                                            scope
                                                                              .row
                                                                              .flowState
                                                                          ) >
                                                                            -1,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.columnBtnsHandel(
                                                                              item.value,
                                                                              scope.row,
                                                                              scope.$index
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                      " +
                                                                          _vm._s(
                                                                            item.label
                                                                          ) +
                                                                          "\n                    "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              : item.value ==
                                                                "detail"
                                                              ? [
                                                                  scope.row.id
                                                                    ? _c(
                                                                        "el-button",
                                                                        {
                                                                          key: i,
                                                                          attrs:
                                                                            {
                                                                              size: "mini",
                                                                              type: "text",
                                                                              disabled:
                                                                                _vm
                                                                                  .config
                                                                                  .enableFlow ==
                                                                                  1 &&
                                                                                !scope
                                                                                  .row
                                                                                  .flowState,
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.columnBtnsHandel(
                                                                                  item.value,
                                                                                  scope.row
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                      " +
                                                                              _vm._s(
                                                                                item.label
                                                                              ) +
                                                                              "\n                    "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              : [
                                                                  _c(
                                                                    "el-button",
                                                                    {
                                                                      key: i,
                                                                      attrs: {
                                                                        size: "mini",
                                                                        type: "text",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.customBtnsHandel(
                                                                              item,
                                                                              scope.row,
                                                                              scope.$index
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                      " +
                                                                          _vm._s(
                                                                            item.label
                                                                          ) +
                                                                          "\n                    "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                          ]
                                                        }
                                                      ),
                                                      _vm.customBtnsList.length
                                                        ? [
                                                            _c(
                                                              "el-dropdown",
                                                              {
                                                                attrs: {
                                                                  "hide-on-click":
                                                                    "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "el-dropdown-link",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        attrs: {
                                                                          type: "text",
                                                                          size: "mini",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                        " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "cip.plat.wfDesign.model.btn.moreBtn"
                                                                              )
                                                                            )
                                                                        ),
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "el-icon-arrow-down el-icon--right",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "el-dropdown-menu",
                                                                  {
                                                                    attrs: {
                                                                      slot: "dropdown",
                                                                    },
                                                                    slot: "dropdown",
                                                                  },
                                                                  _vm._l(
                                                                    _vm.customBtnsList,
                                                                    function (
                                                                      item,
                                                                      i
                                                                    ) {
                                                                      return _c(
                                                                        "el-dropdown-item",
                                                                        {
                                                                          key: i,
                                                                          nativeOn:
                                                                            {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.customBtnsHandel(
                                                                                    item,
                                                                                    scope.row,
                                                                                    scope.$index
                                                                                  )
                                                                                },
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                        " +
                                                                              _vm._s(
                                                                                item.label
                                                                              ) +
                                                                              "\n                      "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ]
                                                  : [
                                                      _vm._l(
                                                        _vm.columnBtnsList,
                                                        function (item, i) {
                                                          return [
                                                            item.value == "edit"
                                                              ? [
                                                                  _vm.columnData
                                                                    .type === 4
                                                                    ? [
                                                                        _c(
                                                                          "el-button",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "has",
                                                                                  rawName:
                                                                                    "v-has",
                                                                                  value:
                                                                                    "btn_" +
                                                                                    item.value,
                                                                                  expression:
                                                                                    "'btn_' + item.value",
                                                                                },
                                                                              ],
                                                                            key: i,
                                                                            attrs:
                                                                              {
                                                                                size: "mini",
                                                                                type: "text",
                                                                                disabled:
                                                                                  _vm
                                                                                    .config
                                                                                    .enableFlow ==
                                                                                    1 &&
                                                                                  [
                                                                                    1,
                                                                                    2,
                                                                                    4,
                                                                                    5,
                                                                                  ].indexOf(
                                                                                    scope
                                                                                      .row
                                                                                      .flowState
                                                                                  ) >
                                                                                    -1,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  scope.row.rowEdit = true
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  item.label
                                                                                ) +
                                                                                "\n                      "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    : [
                                                                        scope
                                                                          .row
                                                                          .btnShow
                                                                          ? _c(
                                                                              "el-button",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "has",
                                                                                      rawName:
                                                                                        "v-has",
                                                                                      value:
                                                                                        "btn_" +
                                                                                        item.value,
                                                                                      expression:
                                                                                        "'btn_' + item.value",
                                                                                    },
                                                                                  ],
                                                                                key: i,
                                                                                attrs:
                                                                                  {
                                                                                    size: "mini",
                                                                                    type: "text",
                                                                                    disabled:
                                                                                      _vm
                                                                                        .config
                                                                                        .enableFlow ==
                                                                                        1 &&
                                                                                      [
                                                                                        1,
                                                                                        2,
                                                                                        4,
                                                                                        5,
                                                                                      ].indexOf(
                                                                                        scope
                                                                                          .row
                                                                                          .flowState
                                                                                      ) >
                                                                                        -1,
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.columnBtnsHandel(
                                                                                        item.value,
                                                                                        scope.row
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                        " +
                                                                                    _vm._s(
                                                                                      item.label
                                                                                    ) +
                                                                                    "\n                      "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                ]
                                                              : item.value ==
                                                                "remove"
                                                              ? [
                                                                  scope.row
                                                                    .btnShow
                                                                    ? _c(
                                                                        "el-button",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "has",
                                                                                rawName:
                                                                                  "v-has",
                                                                                value:
                                                                                  "btn_" +
                                                                                  item.value,
                                                                                expression:
                                                                                  "'btn_' + item.value",
                                                                              },
                                                                            ],
                                                                          key: i,
                                                                          staticClass:
                                                                            "SNOWS-table-delBtn",
                                                                          attrs:
                                                                            {
                                                                              size: "mini",
                                                                              type: "text",
                                                                              disabled:
                                                                                _vm
                                                                                  .config
                                                                                  .enableFlow ==
                                                                                  1 &&
                                                                                [
                                                                                  1,
                                                                                  2,
                                                                                  3,
                                                                                  5,
                                                                                ].indexOf(
                                                                                  scope
                                                                                    .row
                                                                                    .flowState
                                                                                ) >
                                                                                  -1,
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.columnBtnsHandel(
                                                                                  item.value,
                                                                                  scope.row,
                                                                                  scope.$index
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.label
                                                                            ) +
                                                                              "\n                    "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              : item.value ==
                                                                "detail"
                                                              ? [
                                                                  _c(
                                                                    "el-button",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "has",
                                                                            rawName:
                                                                              "v-has",
                                                                            value:
                                                                              "btn_" +
                                                                              item.value,
                                                                            expression:
                                                                              "'btn_' + item.value",
                                                                          },
                                                                        ],
                                                                      key: i,
                                                                      attrs: {
                                                                        size: "mini",
                                                                        type: "text",
                                                                        disabled:
                                                                          _vm
                                                                            .config
                                                                            .enableFlow ==
                                                                            1 &&
                                                                          !scope
                                                                            .row
                                                                            .flowState,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.columnBtnsHandel(
                                                                              item.value,
                                                                              scope.row
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                      " +
                                                                          _vm._s(
                                                                            item.label
                                                                          ) +
                                                                          "\n                    "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              : [
                                                                  _c(
                                                                    "el-button",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "has",
                                                                            rawName:
                                                                              "v-has",
                                                                            value:
                                                                              item.value,
                                                                            expression:
                                                                              "item.value",
                                                                          },
                                                                        ],
                                                                      key: i,
                                                                      attrs: {
                                                                        size: "mini",
                                                                        type: "text",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.customBtnsHandel(
                                                                              item,
                                                                              scope.row,
                                                                              scope.$index
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.label
                                                                        ) +
                                                                          "\n                    "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                          ]
                                                        }
                                                      ),
                                                      _vm.customBtnsList.length
                                                        ? [
                                                            _c(
                                                              "el-dropdown",
                                                              {
                                                                attrs: {
                                                                  "hide-on-click":
                                                                    "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "el-dropdown-link",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        attrs: {
                                                                          type: "text",
                                                                          size: "mini",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                        更多\n                        "
                                                                        ),
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "el-icon-arrow-down el-icon--right",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "el-dropdown-menu",
                                                                  {
                                                                    attrs: {
                                                                      slot: "dropdown",
                                                                    },
                                                                    slot: "dropdown",
                                                                  },
                                                                  _vm._l(
                                                                    _vm.customBtnsList,
                                                                    function (
                                                                      item,
                                                                      i
                                                                    ) {
                                                                      return _c(
                                                                        "el-dropdown-item",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "has",
                                                                                rawName:
                                                                                  "v-has",
                                                                                value:
                                                                                  item.value,
                                                                                expression:
                                                                                  "item.value",
                                                                              },
                                                                            ],
                                                                          key: i,
                                                                          nativeOn:
                                                                            {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.customBtnsHandel(
                                                                                    item,
                                                                                    scope.row,
                                                                                    scope.$index
                                                                                  )
                                                                                },
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.label
                                                                            ) +
                                                                              "\n                      "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                              ],
                                        ]
                                      : undefined
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.columnData.type !== 3 &&
              _vm.columnData.hasPage &&
              _vm.refreshTable
                ? [
                    _c("pagination", {
                      attrs: {
                        total: _vm.total,
                        page: _vm.listQuery.currentPage,
                        limit: _vm.listQuery.pageSize,
                      },
                      on: {
                        "update:page": function ($event) {
                          return _vm.$set(_vm.listQuery, "currentPage", $event)
                        },
                        "update:limit": function ($event) {
                          return _vm.$set(_vm.listQuery, "pageSize", $event)
                        },
                        pagination: _vm.initData,
                      },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm.flowVisible
        ? _c("FlowBox", { ref: "FlowBox", on: { close: _vm.closeFlow } })
        : _vm._e(),
      _c("Form", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.formVisible,
            expression: "formVisible",
          },
        ],
        ref: "Form",
        on: { refreshDataList: _vm.refresh },
      }),
      _c("Detail", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.detailVisible,
            expression: "detailVisible",
          },
        ],
        ref: "Detail",
        on: {
          close: function ($event) {
            _vm.detailVisible = false
          },
        },
      }),
      _vm.exportBoxVisible
        ? _c("ExportBox", { ref: "ExportBox", on: { download: _vm.download } })
        : _vm._e(),
      _vm.uploadBoxVisible
        ? _c("ImportBox", { ref: "UploadBox", on: { refresh: _vm.initData } })
        : _vm._e(),
      _vm.superQueryVisible
        ? _c("SuperQuery", {
            ref: "SuperQuery",
            attrs: { columnOptions: _vm.columnOptions },
            on: { superQuery: _vm.superQuery },
          })
        : _vm._e(),
      _c("candidate-form", {
        attrs: {
          visible: _vm.candidateVisible,
          candidateList: _vm.candidateList,
          branchList: _vm.branchList,
          taskId: "0",
          formData: _vm.workFlowFormData,
          isCustomCopy: _vm.isCustomCopy,
        },
        on: {
          "update:visible": function ($event) {
            _vm.candidateVisible = $event
          },
          submitCandidate: _vm.submitCandidate,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }