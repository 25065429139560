var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "SNOWS-dialog SNOWS-dialog_center",
      attrs: {
        title: _vm.setting.title,
        "close-on-click-modal": false,
        visible: _vm.visible,
        "lock-scroll": "",
        "append-to-body": "",
        width: "1000px",
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "main",
        },
        [
          _c(_vm.currentView, {
            ref: "form",
            tag: "component",
            on: {
              setLoad: _vm.setLoad,
              setCandidateLoad: _vm.setCandidateLoad,
              setPageLoad: _vm.setPageLoad,
            },
          }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }