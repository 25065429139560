var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          staticClass: "SNOWS-full-dialog",
          attrs: {
            "close-on-click-modal": false,
            "modal-append-to-body": false,
            fullscreen: "",
            "lock-scroll": "",
            "show-close": false,
            modal: false,
            "append-to-body": "",
          },
          on: { open: _vm.onOpen },
        },
        "el-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("div", { staticClass: "SNOWS-full-dialog-header" }, [
        _c("div", { staticClass: "header-title" }, [
          _c("img", {
            staticClass: "header-logo",
            attrs: { src: require("@/assets/images/default_avatar.png") },
          }),
          _c("p", { staticClass: "header-txt" }, [_vm._v(" · 打印预览")]),
        ]),
        _c(
          "div",
          { staticClass: "options" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.word },
              },
              [_vm._v("下 载")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.print },
              },
              [_vm._v("打 印")]
            ),
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.closeDialog()
                  },
                },
              },
              [_vm._v("取消")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "main",
        },
        [
          _c("div", {
            ref: "tsPrint",
            staticClass: "print-content",
            domProps: { innerHTML: _vm._s(_vm.printTemplate) },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }