var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "calc(100vh - 120px)" } },
    [
      _vm.showPage
        ? _c(_vm.currentView, {
            tag: "component",
            attrs: {
              config: _vm.config,
              modelId: _vm.modelId,
              isPreview: _vm.isPreview,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }