var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SNOWS-preview-main flow-form-main" },
    [
      _c(
        "div",
        { staticClass: "SNOWS-common-page-header" },
        [
          _vm.setting.fromForm
            ? _c("div", [_vm._v(_vm._s(_vm.title))])
            : _c("el-page-header", {
                attrs: { content: _vm.title },
                on: { back: _vm.goBack },
              }),
          !_vm.loading || _vm.title
            ? [
                _c(
                  "el-dropdown",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.setting.opType == "-1",
                        expression: "setting.opType=='-1'",
                      },
                    ],
                    attrs: { placement: "bottom", trigger: "click" },
                    on: { command: _vm.handleFlowUrgent },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flow-urgent-value",
                        staticStyle: { cursor: "pointer" },
                      },
                      [
                        _c("span", {
                          staticClass: "color-box",
                          style: {
                            "background-color":
                              _vm.flowUrgentList[_vm.selectState].color,
                          },
                        }),
                        _c(
                          "span",
                          {
                            style: {
                              color: _vm.flowUrgentList[_vm.selectState].color,
                            },
                          },
                          [
                            _vm._v(
                              "\n           " +
                                _vm._s(_vm.flowUrgentList[_vm.selectState].name)
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      _vm._l(_vm.flowUrgentList, function (item, index) {
                        return _c(
                          "el-dropdown-item",
                          { key: index, attrs: { command: item.state } },
                          [
                            _c("span", {
                              staticClass: "color-box",
                              style: { "background-color": item.color },
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(item.name) +
                                "\n          "
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.setting.opType !== "-1",
                        expression: "setting.opType!=='-1'",
                      },
                    ],
                    staticClass: "flow-urgent-value",
                  },
                  [
                    _c("span", {
                      staticClass: "color-box",
                      style: {
                        "background-color":
                          _vm.flowUrgentList[_vm.selectState].color,
                      },
                    }),
                    _c(
                      "span",
                      {
                        style: {
                          color: _vm.flowUrgentList[_vm.selectState].color,
                        },
                      },
                      [_vm._v(_vm._s(_vm.flowUrgentList[_vm.selectState].name))]
                    ),
                  ]
                ),
              ]
            : _vm._e(),
          _c(
            "div",
            { staticClass: "options" },
            [
              _vm.moreBtnList.length
                ? _c(
                    "el-dropdown",
                    {
                      staticClass: "dropdown",
                      attrs: { placement: "bottom" },
                      on: { command: _vm.handleMore },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "70px" },
                          attrs: { disabled: _vm.allBtnDisabled },
                        },
                        [
                          _vm._v("\n          更 多"),
                          _c("i", {
                            staticClass: "el-icon-arrow-down el-icon--right",
                          }),
                        ]
                      ),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        _vm._l(_vm.moreBtnList, function (item, index) {
                          return _c(
                            "el-dropdown-item",
                            {
                              key: index,
                              staticClass: "dropdown-item",
                              attrs: { command: item.key },
                            },
                            [_vm._v(_vm._s(item.label) + "\n          ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.setting.opType == "-1"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.candidateLoading,
                        disabled: _vm.allBtnDisabled,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.eventLauncher("submit")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.properties.submitBtnText || "提 交") +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.setting.opType == 1 && _vm.properties.hasAuditBtn
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.candidateLoading },
                      on: {
                        click: function ($event) {
                          return _vm.eventLauncher("audit")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.properties.auditBtnText || "通 过") +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.setting.opType == 0 &&
              _vm.setting.status == 1 &&
              (_vm.properties.hasPressBtn ||
                _vm.properties.hasPressBtn === undefined)
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.press()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.properties.pressBtnText || "催 办") +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.setting.opType == 2 && _vm.properties.hasRevokeBtn
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.actionLauncher("recall")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.properties.revokeBtnText || "撤 回") +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.setting.opType == 4 && _vm.setting.status == 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.actionLauncher("cancel")
                        },
                      },
                    },
                    [_vm._v("\n        终 止\n      ")]
                  )
                : _vm._e(),
              !_vm.setting.hideCancelBtn
                ? _c(
                    "el-button",
                    {
                      attrs: { disabled: _vm.allBtnDisabled },
                      on: {
                        click: function ($event) {
                          return _vm.goBack()
                        },
                      },
                    },
                    [_vm._v("\n        取消\n      ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      (_vm.setting.opType == 0 || _vm.setting.opType == 4) &&
      _vm.activeTab === "0"
        ? _c("div", { staticClass: "approve-result" }, [
            _c("div", {
              staticClass: "approve-result-img",
              class: _vm._f("flowStatus")(_vm.flowTaskInfo.status),
            }),
          ])
        : _vm._e(),
      _c(_vm.currentView, {
        ref: "form",
        tag: "component",
        on: {
          close: _vm.goBack,
          eventReceiver: _vm.eventReceiver,
          setLoad: _vm.setLoad,
          setCandidateLoad: _vm.setCandidateLoad,
          setPageLoad: _vm.setPageLoad,
        },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "SNOWS-dialog SNOWS-dialog_center",
          attrs: {
            title: _vm.eventType === "audit" ? "审批通过" : "审批退回",
            "close-on-click-modal": false,
            visible: _vm.visible,
            "lock-scroll": "",
            "append-to-body": "",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "candidateForm",
              attrs: {
                model: _vm.candidateForm,
                "label-width":
                  _vm.candidateForm.candidateList.length ||
                  _vm.branchList.length
                    ? "130px"
                    : "80px",
              },
            },
            [
              _vm.eventType == "audit"
                ? [
                    _vm.branchList.length
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "分支选择",
                              prop: "branchList",
                              rules: [
                                {
                                  required: true,
                                  message: `分支不能为空`,
                                  trigger: "change",
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  multiple: "",
                                  placeholder: "请选择审批分支",
                                  clearable: "",
                                },
                                on: { change: _vm.onBranchChange },
                                model: {
                                  value: _vm.candidateForm.branchList,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.candidateForm,
                                      "branchList",
                                      $$v
                                    )
                                  },
                                  expression: "candidateForm.branchList",
                                },
                              },
                              _vm._l(_vm.branchList, function (item) {
                                return _c("el-option", {
                                  key: item.nodeId,
                                  attrs: {
                                    label: item.nodeName,
                                    value: item.nodeId,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._l(_vm.candidateForm.candidateList, function (item, i) {
                      return _c(
                        "el-form-item",
                        {
                          key: i,
                          attrs: {
                            label: item.nodeName + item.label,
                            prop: "candidateList." + i + ".value",
                            rules: item.rules,
                          },
                        },
                        [
                          item.hasCandidates
                            ? _c("candidate-user-select", {
                                attrs: {
                                  multiple: "",
                                  placeholder: "请选择" + item.label,
                                  taskId: _vm.setting.taskId,
                                  formData: _vm.formData,
                                  nodeId: item.nodeId,
                                },
                                model: {
                                  value: item.value,
                                  callback: function ($$v) {
                                    _vm.$set(item, "value", $$v)
                                  },
                                  expression: "item.value",
                                },
                              })
                            : _c("user-select", {
                                attrs: {
                                  multiple: "",
                                  placeholder: "请选择" + item.label,
                                  title: "候选人员",
                                },
                                model: {
                                  value: item.value,
                                  callback: function ($$v) {
                                    _vm.$set(item, "value", $$v)
                                  },
                                  expression: "item.value",
                                },
                              }),
                        ],
                        1
                      )
                    }),
                  ]
                : _vm._e(),
              _vm.properties.rejectType &&
              _vm.eventType !== "audit" &&
              _vm.showReject
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "退回节点", prop: "rejectStep" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择退回节点",
                              disabled: _vm.properties.rejectStep !== "2",
                            },
                            model: {
                              value: _vm.candidateForm.rejectStep,
                              callback: function ($$v) {
                                _vm.$set(_vm.candidateForm, "rejectStep", $$v)
                              },
                              expression: "candidateForm.rejectStep",
                            },
                          },
                          _vm._l(_vm.rejectList, function (item) {
                            return _c("el-option", {
                              key: item.nodeCode,
                              attrs: {
                                label: item.nodeName,
                                value: item.nodeCode,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.properties.hasOpinion
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "审批意见", prop: "handleOpinion" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入审批意见",
                            type: "textarea",
                            rows: 4,
                          },
                          model: {
                            value: _vm.candidateForm.handleOpinion,
                            callback: function ($$v) {
                              _vm.$set(_vm.candidateForm, "handleOpinion", $$v)
                            },
                            expression: "candidateForm.handleOpinion",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "审批附件", prop: "fileList" } },
                      [
                        _c("SNOWS-UploadFz", {
                          attrs: { limit: 3 },
                          model: {
                            value: _vm.candidateForm.fileList,
                            callback: function ($$v) {
                              _vm.$set(_vm.candidateForm, "fileList", $$v)
                            },
                            expression: "candidateForm.fileList",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.properties.hasSign
                ? _c(
                    "el-form-item",
                    { attrs: { label: "手写签名", required: "" } },
                    [
                      _c("div", { staticClass: "sign-main" }, [
                        _vm.signImg
                          ? _c("img", {
                              staticClass: "sign-img",
                              attrs: { src: _vm.signImg, alt: "" },
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "sign-style",
                            on: { click: _vm.addSign },
                          },
                          [
                            _c("i", {
                              staticClass: "icon-ym icon-ym-signature add-sign",
                            }),
                            !_vm.signImg
                              ? _c("span", { staticClass: "sign-title" }, [
                                  _vm._v("手写签名"),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.properties.isCustomCopy
                ? _c(
                    "el-form-item",
                    { attrs: { label: "抄送人员" } },
                    [
                      _c("user-select", {
                        attrs: { placeholder: "请选择", multiple: "" },
                        model: {
                          value: _vm.copyIds,
                          callback: function ($$v) {
                            _vm.copyIds = $$v
                          },
                          expression: "copyIds",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.approvalBtnLoading },
                  on: {
                    click: function ($event) {
                      return _vm.handleApproval()
                    },
                  },
                },
                [_vm._v("\n        确定\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "SNOWS-dialog SNOWS-dialog_center",
          attrs: {
            title: _vm.flowTaskInfo.completion == 100 ? "复活" : "变更",
            "close-on-click-modal": false,
            visible: _vm.resurgenceVisible,
            "lock-scroll": "",
            "append-to-body": "",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.resurgenceVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "resurgenceForm",
              attrs: {
                "label-width": "80px",
                model: _vm.resurgenceForm,
                rules: _vm.resurgenceRules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      _vm.flowTaskInfo.completion == 100
                        ? "复活节点"
                        : "变更节点",
                    prop: "taskNodeId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder:
                          _vm.flowTaskInfo.completion == 100
                            ? "请选择复活节点"
                            : "请选择变更节点",
                      },
                      model: {
                        value: _vm.resurgenceForm.taskNodeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.resurgenceForm, "taskNodeId", $$v)
                        },
                        expression: "resurgenceForm.taskNodeId",
                      },
                    },
                    _vm._l(_vm.resurgenceNodeList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.nodeName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      _vm.flowTaskInfo.completion == 100
                        ? "复活意见"
                        : "变更意见",
                    prop: "handleOpinion",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请填写意见",
                      rows: 4,
                    },
                    model: {
                      value: _vm.resurgenceForm.handleOpinion,
                      callback: function ($$v) {
                        _vm.$set(_vm.resurgenceForm, "handleOpinion", $$v)
                      },
                      expression: "resurgenceForm.handleOpinion",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      _vm.flowTaskInfo.completion == 100
                        ? "复活附件"
                        : "变更附件",
                    prop: "fileList",
                  },
                },
                [
                  _c("SNOWS-UploadFz", {
                    attrs: { limit: 3 },
                    model: {
                      value: _vm.resurgenceForm.fileList,
                      callback: function ($$v) {
                        _vm.$set(_vm.resurgenceForm, "fileList", $$v)
                      },
                      expression: "resurgenceForm.fileList",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.resurgenceVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.resurgenceBtnLoading },
                  on: {
                    click: function ($event) {
                      return _vm.handleResurgence()
                    },
                  },
                },
                [_vm._v("\n        确定\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("print-browse", {
        attrs: {
          visible: _vm.printBrowseVisible,
          id: _vm.properties.printId,
          formId: _vm.setting.id,
          fullName: _vm.setting.fullName,
        },
        on: {
          "update:visible": function ($event) {
            _vm.printBrowseVisible = $event
          },
        },
      }),
      _c("candidate-form", {
        attrs: {
          visible: _vm.candidateVisible,
          candidateList: _vm.candidateList,
          branchList: _vm.branchList,
          taskId: _vm.setting.taskId,
          formData: _vm.formData,
          isCustomCopy: _vm.properties.isCustomCopy,
        },
        on: {
          "update:visible": function ($event) {
            _vm.candidateVisible = $event
          },
          submitCandidate: _vm.submitCandidate,
        },
      }),
      _c("error-form", {
        attrs: { visible: _vm.errorVisible, nodeList: _vm.errorNodeList },
        on: {
          "update:visible": function ($event) {
            _vm.errorVisible = $event
          },
          submit: _vm.handleError,
        },
      }),
      _vm.actionVisible
        ? _c("actionDialog", {
            ref: "actionDialog",
            attrs: { assignNodeList: _vm.assignNodeList },
            on: { submit: _vm.actionReceiver },
          })
        : _vm._e(),
      _c("HasFreeApprover", {
        attrs: {
          visible: _vm.hasFreeApproverVisible,
          taskId: _vm.setting.taskId,
          formData: _vm.formData,
          properties: _vm.properties,
        },
        on: {
          "update:visible": function ($event) {
            _vm.hasFreeApproverVisible = $event
          },
          close: _vm.approverDialog,
        },
      }),
      _vm.signVisible
        ? _c("SignImgDialog", {
            ref: "SignImg",
            attrs: { lineWidth: 3, userInfo: _vm.userInfo, isDefault: 1 },
            on: { close: _vm.signDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }