var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "SNOWS-dialog SNOWS-dialog_center",
      attrs: {
        title: _vm.title,
        "close-on-click-modal": false,
        width: "600px",
        visible: _vm.visible,
        "lock-scroll": "",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.dataForm,
            "label-width": "80px",
            rules: _vm.fromRules,
          },
        },
        [
          _vm.eventType === "transfer" ||
          _vm.eventType === "assign" ||
          _vm.eventType === "cancel"
            ? [
                _vm.eventType === "assign"
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "指派节点", prop: "nodeCode" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择指派节点" },
                            model: {
                              value: _vm.dataForm.nodeCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "nodeCode", $$v)
                              },
                              expression: "dataForm.nodeCode",
                            },
                          },
                          _vm._l(_vm.assignNodeList, function (item) {
                            return _c("el-option", {
                              key: item.nodeCode,
                              attrs: {
                                label: item.nodeName,
                                value: item.nodeCode,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.eventType === "transfer" || _vm.eventType === "assign"
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.label + "给谁",
                          prop: "freeApproverUserId",
                          rules: [
                            {
                              required: true,
                              message: `请选择${_vm.label}给谁`,
                              trigger: "click",
                            },
                          ],
                        },
                      },
                      [
                        _c("user-select", {
                          attrs: { placeholder: `请选择${_vm.label}给谁` },
                          model: {
                            value: _vm.dataForm.freeApproverUserId,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "freeApproverUserId", $$v)
                            },
                            expression: "dataForm.freeApproverUserId",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.eventType === "assign" ||
                _vm.eventType === "cancel" ||
                (_vm.eventType === "transfer" &&
                  _vm.properties &&
                  _vm.properties.hasOpinion)
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: `${_vm.label}原因`,
                          prop: "handleOpinion",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: `请输入${_vm.label}原因`,
                            type: "textarea",
                            rows: 4,
                          },
                          model: {
                            value: _vm.dataForm.handleOpinion,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "handleOpinion", $$v)
                            },
                            expression: "dataForm.handleOpinion",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.eventType === "assign" ||
                _vm.eventType === "cancel" ||
                (_vm.eventType === "transfer" &&
                  _vm.properties &&
                  _vm.properties.hasOpinion)
                  ? _c(
                      "el-form-item",
                      {
                        attrs: { label: `${_vm.label}附件`, prop: "fileList" },
                      },
                      [
                        _c("SNOWS-UploadFz", {
                          attrs: { limit: 3 },
                          model: {
                            value: _vm.dataForm.fileList,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "fileList", $$v)
                            },
                            expression: "dataForm.fileList",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.properties.hasSign && _vm.eventType === "transfer"
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "手写签名", required: "" } },
                      [
                        _c("div", { staticClass: "sign-main" }, [
                          _vm.signImg
                            ? _c("img", {
                                staticClass: "sign-img",
                                attrs: { src: _vm.signImg, alt: "" },
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "sign-style",
                              on: { click: _vm.addSign },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "icon-ym icon-ym-signature add-sign",
                              }),
                              !_vm.signImg
                                ? _c("span", { staticClass: "sign-title" }, [
                                    _vm._v("手写签名"),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            : [
                _vm.properties.hasOpinion
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: `${_vm.label}原因`,
                            prop: "handleOpinion",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: `请输入${_vm.label}原因`,
                              type: "textarea",
                              rows: 4,
                            },
                            model: {
                              value: _vm.dataForm.handleOpinion,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "handleOpinion", $$v)
                              },
                              expression: "dataForm.handleOpinion",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: `${_vm.label}附件`,
                            prop: "fileList",
                          },
                        },
                        [
                          _c("SNOWS-UploadFz", {
                            attrs: { limit: 3 },
                            model: {
                              value: _vm.dataForm.fileList,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "fileList", $$v)
                              },
                              expression: "dataForm.fileList",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.properties.hasSign
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "手写签名", required: "" } },
                      [
                        _c("div", { staticClass: "sign-main" }, [
                          _vm.signImg
                            ? _c("img", {
                                staticClass: "sign-img",
                                attrs: { src: _vm.signImg, alt: "" },
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "sign-style",
                              on: { click: _vm.addSign },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "icon-ym icon-ym-signature add-sign",
                              }),
                              !_vm.signImg
                                ? _c("span", { staticClass: "sign-title" }, [
                                    _vm._v("手写签名"),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
        ],
        2
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnLoading },
              on: {
                click: function ($event) {
                  return _vm.handleSure()
                },
              },
            },
            [_vm._v("\n      确定\n    ")]
          ),
        ],
        1
      ),
      _vm.signVisible
        ? _c("SignImgDialog", {
            ref: "SignImg",
            attrs: { lineWidth: 3, userInfo: _vm.userInfo, isDefault: 1 },
            on: { close: _vm.signDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }