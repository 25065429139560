var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comment-container" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading && _vm.listQuery.currentPage == 1,
              expression: "listLoading && listQuery.currentPage==1",
            },
          ],
          ref: "infiniteBody",
          staticClass: "comment-list",
        },
        [
          _vm.list.length
            ? _vm._l(_vm.list, function (item, i) {
                return _c("div", { key: i, staticClass: "item" }, [
                  _c(
                    "div",
                    { staticClass: "head" },
                    [
                      _c("el-avatar", {
                        staticClass: "avatar",
                        attrs: {
                          size: 40,
                          src: _vm.define.comUrl + item.creatorUserHeadIcon,
                        },
                      }),
                      _c("p", { staticClass: "username" }, [
                        _vm._v(_vm._s(item.creatorUser)),
                      ]),
                      item.isDel
                        ? _c(
                            "el-link",
                            {
                              staticClass: "del-btn",
                              attrs: { underline: false, type: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.delComment(item.id, i)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e(),
                      _c("span", { staticClass: "time" }, [
                        _vm._v(_vm._s(_vm._f("toDate")(item.creatorTime))),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "content" }, [
                    _c("p", { staticClass: "text" }, [
                      _vm._v(_vm._s(item.text)),
                    ]),
                    item.imageList.length
                      ? _c(
                          "div",
                          { staticClass: "img-list" },
                          _vm._l(item.imageList, function (cItem, ci) {
                            return _c("el-image", {
                              key: ci,
                              staticClass: "img-item",
                              attrs: {
                                src: _vm.define.comUrl + cItem.url,
                                "preview-src-list": _vm.getImgList(
                                  item.imageList
                                ),
                                "z-index": 10000,
                              },
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                    item.fileList.length
                      ? _c(
                          "div",
                          { staticClass: "file-List" },
                          [
                            _c("SNOWS-UploadFz", {
                              attrs: { detailed: "", disabled: "" },
                              model: {
                                value: item.fileList,
                                callback: function ($$v) {
                                  _vm.$set(item, "fileList", $$v)
                                },
                                expression: "item.fileList",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ])
              })
            : _c("el-empty", {
                attrs: { description: "暂无数据", "image-size": 120 },
              }),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          staticClass: "SNOWS-dialog SNOWS-dialog_center",
          attrs: {
            title: "流程评论",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "lock-scroll": "",
            "append-to-body": "",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.dataForm,
                rules: _vm.dataRule,
                "label-width": "0",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "text" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入评论",
                      type: "textarea",
                      rows: 4,
                    },
                    model: {
                      value: _vm.dataForm.text,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "text", $$v)
                      },
                      expression: "dataForm.text",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-upload",
                    {
                      ref: "elUploadImg",
                      staticClass: "upload-btn",
                      attrs: {
                        action: _vm.define.comUploadUrl + "/annexpic",
                        headers: _vm.uploadHeaders,
                        "on-success": _vm.handleImgSuccess,
                        multiple: "",
                        "show-file-list": false,
                        accept: "image/*",
                        "before-upload": _vm.beforeImgUpload,
                        "on-exceed": _vm.handleImgExceed,
                        limit: 9,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", icon: "el-icon-picture" } },
                        [_vm._v("图片")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", icon: "el-icon-upload" },
                      on: { click: _vm.uploadFile },
                    },
                    [_vm._v("附件")]
                  ),
                ],
                1
              ),
              _vm.dataForm.image.length
                ? _c("el-form-item", { attrs: { prop: "image" } }, [
                    _c(
                      "div",
                      { staticClass: "img-list" },
                      _vm._l(_vm.dataForm.image, function (item, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "img-item" },
                          [
                            _c("el-image", {
                              attrs: {
                                src: _vm.define.comUrl + item.url,
                                "preview-src-list": _vm.getImgList(
                                  _vm.dataForm.image
                                ),
                                "z-index": 100,
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "badge",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleImgRemove(i)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.dataForm.file.length,
                      expression: "dataForm.file.length",
                    },
                  ],
                  attrs: { prop: "file" },
                },
                [
                  _c(
                    "ul",
                    {
                      staticClass:
                        "el-upload-list el-upload-list el-upload-list--text",
                    },
                    _vm._l(_vm.dataForm.file, function (file, index) {
                      return _c(
                        "li",
                        {
                          key: file.fileId,
                          staticClass: "el-upload-list__item is-success",
                        },
                        [
                          _c(
                            "a",
                            { staticClass: "el-upload-list__item-name" },
                            [
                              _c("i", { staticClass: "el-icon-paperclip" }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(file.name) +
                                  _vm._s(
                                    file.fileSize
                                      ? `（${_vm.jnpf.toFileSize(
                                          file.fileSize
                                        )}）`
                                      : ""
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _c("i", {
                            staticClass: "el-icon-view",
                            attrs: { title: "查看" },
                            on: {
                              click: function ($event) {
                                return _vm.handleFilePreview(file)
                              },
                            },
                          }),
                          _c("i", {
                            staticClass: "el-icon-download",
                            attrs: { title: "下载" },
                            on: {
                              click: function ($event) {
                                return _vm.handleFileClick(file)
                              },
                            },
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "el-upload-list__item-status-label",
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-upload-success el-icon-circle-check",
                              }),
                            ]
                          ),
                          _c("i", {
                            staticClass: "el-icon-close",
                            attrs: { title: "删除" },
                            on: {
                              click: function ($event) {
                                return _vm.handleFileRemove(index)
                              },
                            },
                          }),
                        ]
                      )
                    }),
                    0
                  ),
                  _c("fileUploader", {
                    ref: "fileUploader",
                    attrs: {
                      limit: 2,
                      fileSize: 50,
                      type: "annex",
                      accept: _vm.fileAccept,
                    },
                    on: { fileSuccess: _vm.fileSuccess },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消\n      ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function ($event) {
                      return _vm.addComment()
                    },
                  },
                },
                [_vm._v("\n        确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Preview", {
        attrs: { visible: _vm.previewVisible, file: _vm.activeFile },
        on: {
          "update:visible": function ($event) {
            _vm.previewVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }