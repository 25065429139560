var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          staticClass: "SNOWS-dialog SNOWS-dialog_center",
          attrs: {
            "close-on-click-modal": false,
            "modal-append-to-body": false,
            "append-to-body": "",
            title: "加签",
            width: "600px",
          },
          on: { open: _vm.onOpen, close: _vm.onClose },
        },
        "el-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "el-form",
        {
          ref: "hasFreeApproverForm",
          attrs: {
            rules: _vm.rules,
            model: _vm.hasFreeApproverForm,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "加签人员", prop: "freeApproverUserId" } },
            [
              _c("user-select", {
                attrs: { placeholder: "请选择加签人员" },
                model: {
                  value: _vm.hasFreeApproverForm.freeApproverUserId,
                  callback: function ($$v) {
                    _vm.$set(_vm.hasFreeApproverForm, "freeApproverUserId", $$v)
                  },
                  expression: "hasFreeApproverForm.freeApproverUserId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "加签类型" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.hasFreeApproverForm.freeApproverType,
                    callback: function ($$v) {
                      _vm.$set(_vm.hasFreeApproverForm, "freeApproverType", $$v)
                    },
                    expression: "hasFreeApproverForm.freeApproverType",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: 1 } }, [
                    _vm._v("审批前"),
                  ]),
                  _c("el-radio-button", { attrs: { label: 2 } }, [
                    _vm._v("审批后"),
                  ]),
                ],
                1
              ),
              _vm.hasFreeApproverForm.freeApproverType == 1
                ? _c("div", [
                    _vm._v("加签后，流程先经过加签审批人，再由我审批"),
                  ])
                : _c("div", [
                    _vm._v("审批后加签，即表示同意该申请并增加审批人员"),
                  ]),
            ],
            1
          ),
          _vm.branchList.length &&
          _vm.hasFreeApproverForm.freeApproverType === 2
            ? _c(
                "el-form-item",
                { attrs: { label: "分支选择", prop: "branchList" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        placeholder: "请选择审批分支",
                        clearable: "",
                      },
                      on: { change: _vm.onBranchChange },
                      model: {
                        value: _vm.hasFreeApproverForm.branchList,
                        callback: function ($$v) {
                          _vm.$set(_vm.hasFreeApproverForm, "branchList", $$v)
                        },
                        expression: "hasFreeApproverForm.branchList",
                      },
                    },
                    _vm._l(_vm.branchList, function (item) {
                      return _c("el-option", {
                        key: item.nodeId,
                        attrs: { label: item.nodeName, value: item.nodeId },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.hasFreeApproverForm.freeApproverType == 2
            ? _c(
                "div",
                _vm._l(
                  _vm.hasFreeApproverForm.candidateList,
                  function (item, i) {
                    return _c(
                      "el-form-item",
                      {
                        key: i,
                        attrs: {
                          label: item.nodeName + item.label,
                          prop: "candidateList." + i + ".value",
                          rules: item.rules,
                        },
                      },
                      [
                        item.hasCandidates
                          ? _c("candidate-user-select", {
                              attrs: {
                                multiple: "",
                                placeholder: "请选择" + item.label,
                                taskId: _vm.taskId,
                                formData: _vm.formData,
                                nodeId: item.nodeId,
                              },
                              model: {
                                value: item.value,
                                callback: function ($$v) {
                                  _vm.$set(item, "value", $$v)
                                },
                                expression: "item.value",
                              },
                            })
                          : _c("user-select", {
                              attrs: {
                                multiple: "",
                                placeholder: "请选择" + item.label,
                                title: "候选人员",
                              },
                              model: {
                                value: item.value,
                                callback: function ($$v) {
                                  _vm.$set(item, "value", $$v)
                                },
                                expression: "item.value",
                              },
                            }),
                      ],
                      1
                    )
                  }
                ),
                1
              )
            : _vm._e(),
          _vm.properties && _vm.properties.hasOpinion
            ? _c(
                "el-form-item",
                { attrs: { label: "加签原因", prop: "handleOpinion" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入加签原因",
                      type: "textarea",
                      rows: 4,
                    },
                    model: {
                      value: _vm.hasFreeApproverForm.handleOpinion,
                      callback: function ($$v) {
                        _vm.$set(_vm.hasFreeApproverForm, "handleOpinion", $$v)
                      },
                      expression: "hasFreeApproverForm.handleOpinion",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.properties && _vm.properties.hasOpinion
            ? _c(
                "el-form-item",
                { attrs: { label: "加签附件", prop: "fileList" } },
                [
                  _c("SNOWS-UploadFz", {
                    attrs: { limit: 3 },
                    model: {
                      value: _vm.hasFreeApproverForm.fileList,
                      callback: function ($$v) {
                        _vm.$set(_vm.hasFreeApproverForm, "fileList", $$v)
                      },
                      expression: "hasFreeApproverForm.fileList",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.properties && _vm.properties.hasSign
            ? _c(
                "el-form-item",
                { attrs: { label: "手写签名", required: "" } },
                [
                  _c("div", { staticClass: "sign-main" }, [
                    _vm.signImg
                      ? _c("img", {
                          staticClass: "sign-img",
                          attrs: { src: _vm.signImg, alt: "" },
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "sign-style", on: { click: _vm.addSign } },
                      [
                        _c("i", {
                          staticClass: "icon-ym icon-ym-signature add-sign",
                        }),
                        !_vm.signImg
                          ? _c("span", { staticClass: "sign-title" }, [
                              _vm._v("手写签名"),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.formConf.cancelButtonText || "取 消")),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnLoading },
              on: {
                click: function ($event) {
                  return _vm.dataFormSubmit()
                },
              },
            },
            [_vm._v("\n      确定")]
          ),
        ],
        1
      ),
      _vm.signVisible
        ? _c("SignImgDialog", {
            ref: "SignImg",
            attrs: { lineWidth: 3, userInfo: _vm.userInfo, isDefault: 1 },
            on: { close: _vm.signDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }