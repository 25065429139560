var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.item.__config__.noShow &&
    (!_vm.item.__config__.visibility ||
      (Array.isArray(_vm.item.__config__.visibility) &&
        _vm.item.__config__.visibility.includes("pc")))
    ? _c(
        "el-col",
        {
          class: {
            "item-card": _vm.item.__config__.jnpfKey === "card",
            "item-table": _vm.item.__config__.jnpfKey === "table",
          },
          attrs: { span: _vm.item.__config__.span },
        },
        [
          _vm.item.__config__.layout === "colFormItem"
            ? [
                _vm.item.__config__.jnpfKey === "divider"
                  ? [
                      _c(
                        "el-divider",
                        {
                          attrs: {
                            "content-position": _vm.item["content-position"],
                          },
                        },
                        [_vm._v(_vm._s(_vm.item.__slot__.default) + "\n      ")]
                      ),
                    ]
                  : _vm.item.__config__.jnpfKey === "SNOWSText"
                  ? [
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "0" } },
                        [
                          _c("SNOWSText", {
                            attrs: {
                              lineHeight: _vm.item.lineHeight,
                              fontSize: _vm.item.fontSize,
                              textStyle: _vm.item.textStyle,
                            },
                            model: {
                              value: _vm.item.__config__.defaultValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.item.__config__,
                                  "defaultValue",
                                  $$v
                                )
                              },
                              expression: "item.__config__.defaultValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm.item.__config__.jnpfKey === "link"
                  ? [
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "0" } },
                        [
                          _c("jnpf-link", {
                            attrs: {
                              content: _vm.item.content,
                              href: _vm.item.href,
                              target: _vm.item.target,
                              textStyle: _vm.item.textStyle,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm.item.__config__.jnpfKey === "alert"
                  ? [
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "0" } },
                        [
                          _c("el-alert", {
                            attrs: {
                              title: _vm.item.title,
                              type: _vm.item.type,
                              closable: _vm.item.closable,
                              "show-icon": _vm.item["show-icon"],
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm.item.__config__.jnpfKey === "groupTitle"
                  ? [
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "0" } },
                        [
                          _c("groupTitle", {
                            attrs: {
                              content: _vm.item.content,
                              "content-position": _vm.item["content-position"],
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm.item.__config__.jnpfKey === "button"
                  ? [
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "0" } },
                        [
                          _c("jnpf-button", {
                            attrs: {
                              align: _vm.item.align,
                              buttonText: _vm.item.buttonText,
                              type: _vm.item.type,
                              disabled: _vm.item.disabled,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: _vm.item.__vModel__,
                            "label-width": _vm.item.__config__.labelWidth
                              ? `${_vm.item.__config__.labelWidth}px`
                              : null,
                            label: _vm.item.__config__.showLabel
                              ? _vm.item.__config__.label
                              : "",
                          },
                        },
                        [
                          _vm.item.__config__.jnpfKey === "uploadFz"
                            ? [
                                _c("SNOWSUploadFz", {
                                  attrs: { detailed: "", disabled: "" },
                                  model: {
                                    value: _vm.item.__config__.defaultValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.item.__config__,
                                        "defaultValue",
                                        $$v
                                      )
                                    },
                                    expression: "item.__config__.defaultValue",
                                  },
                                }),
                              ]
                            : _vm.item.__config__.jnpfKey === "uploadImg"
                            ? _vm._l(
                                _vm.item.__config__.defaultValue,
                                function (cItem, ci) {
                                  return _c("el-image", {
                                    key: ci,
                                    staticClass: "dy-img",
                                    attrs: {
                                      src: _vm.define.comUrl + cItem.url,
                                      "preview-src-list": _vm.getImgList(
                                        _vm.item.__config__.defaultValue
                                      ),
                                      "z-index": 10000,
                                    },
                                  })
                                }
                              )
                            : _vm.item.__config__.jnpfKey === "colorPicker"
                            ? [
                                _c("el-color-picker", {
                                  attrs: {
                                    "show-alpha": _vm.item["show-alpha"],
                                    "color-format": _vm.item["color-format"],
                                    size: _vm.item.size,
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.item.__config__.defaultValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.item.__config__,
                                        "defaultValue",
                                        $$v
                                      )
                                    },
                                    expression: "item.__config__.defaultValue",
                                  },
                                }),
                              ]
                            : _vm.item.__config__.jnpfKey === "rate"
                            ? [
                                _c("el-rate", {
                                  attrs: {
                                    max: _vm.item.max,
                                    "allow-half": _vm.item["allow-half"],
                                    "show-text": _vm.item["show-text"],
                                    "show-score": _vm.item["show-score"],
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.item.__config__.defaultValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.item.__config__,
                                        "defaultValue",
                                        $$v
                                      )
                                    },
                                    expression: "item.__config__.defaultValue",
                                  },
                                }),
                              ]
                            : _vm.item.__config__.jnpfKey === "slider"
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "slider-box" },
                                  [
                                    _c("el-slider", {
                                      attrs: {
                                        range: _vm.item.range,
                                        "show-stops": _vm.item["show-stops"],
                                      },
                                      model: {
                                        value: _vm.item.__config__.defaultValue,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item.__config__,
                                            "defaultValue",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.__config__.defaultValue",
                                      },
                                    }),
                                    _c("div", { staticClass: "slider-mark" }),
                                  ],
                                  1
                                ),
                              ]
                            : _vm.item.__config__.jnpfKey === "editor"
                            ? [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.item.__config__.defaultValue
                                    ),
                                  },
                                }),
                              ]
                            : _vm.item.__config__.jnpfKey === "relationForm"
                            ? [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      underline: false,
                                      type: "primary",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.toDetail(_vm.item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " + _vm._s(_vm.item.name)
                                    ),
                                  ]
                                ),
                              ]
                            : _vm.item.__config__.jnpfKey === "popupSelect"
                            ? [_c("p", [_vm._v(_vm._s(_vm.item.name))])]
                            : ["relationFormAttr", "popupAttr"].includes(
                                _vm.item.__config__.jnpfKey
                              )
                            ? [
                                !_vm.item.__vModel__
                                  ? _c("p", [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.relationData[
                                              _vm.item.relationField
                                            ] &&
                                              _vm.relationData[
                                                _vm.item.relationField
                                              ][_vm.item.showField]
                                              ? _vm.relationData[
                                                  _vm.item.relationField
                                                ][_vm.item.showField]
                                              : ""
                                          ) +
                                          "\n          "
                                      ),
                                    ])
                                  : _c("p", [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.item.__config__.defaultValue
                                          ) +
                                          "\n          "
                                      ),
                                    ]),
                              ]
                            : _vm.item.__config__.jnpfKey === "barcode"
                            ? [
                                _c("jnpf-barcode", {
                                  attrs: {
                                    format: _vm.item.format,
                                    lineColor: _vm.item.lineColor,
                                    background: _vm.item.background,
                                    width: _vm.item.width,
                                    height: _vm.item.height,
                                    staticText: _vm.item.staticText,
                                    dataType: _vm.item.dataType,
                                    relationField:
                                      _vm.item.relationField + "_id",
                                    formData: _vm.formValue,
                                  },
                                }),
                              ]
                            : _vm.item.__config__.jnpfKey === "qrcode"
                            ? [
                                _c("jnpf-qrcode", {
                                  attrs: {
                                    format: _vm.item.format,
                                    colorLight: _vm.item.colorLight,
                                    colorDark: _vm.item.colorDark,
                                    size: _vm.item.size,
                                    staticText: _vm.item.staticText,
                                    dataType: _vm.item.dataType,
                                    relationField:
                                      _vm.item.relationField + "_id",
                                    formData: _vm.formValue,
                                  },
                                }),
                              ]
                            : [
                                _c("p", { staticClass: "jnpf-detail-text" }, [
                                  _vm.item.__slot__ && _vm.item.__slot__.prepend
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.item.__slot__.prepend)
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(_vm._s(_vm.getValue(_vm.item))),
                                  _vm.item.__slot__ && _vm.item.__slot__.append
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.item.__slot__.append)
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ],
                        ],
                        2
                      ),
                    ],
              ]
            : [
                _vm.item.__config__.jnpfKey === "card"
                  ? [
                      _c(
                        "el-card",
                        {
                          staticClass: "mb-20",
                          attrs: {
                            shadow: _vm.item.shadow,
                            header: _vm.item.header,
                          },
                        },
                        _vm._l(
                          _vm.item.__config__.children,
                          function (childItem, childIndex) {
                            return _c(
                              "Item",
                              _vm._g(
                                _vm._b(
                                  {
                                    key: childIndex,
                                    attrs: { item: childItem },
                                  },
                                  "Item",
                                  _vm.$props,
                                  false
                                ),
                                _vm.$listeners
                              )
                            )
                          }
                        ),
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.item.__config__.jnpfKey === "row"
                  ? [
                      _c(
                        "el-row",
                        { attrs: { gutter: _vm.formConf.gutter } },
                        _vm._l(
                          _vm.item.__config__.children,
                          function (childItem, childIndex) {
                            return _c(
                              "Item",
                              _vm._g(
                                _vm._b(
                                  {
                                    key: childIndex,
                                    attrs: { item: childItem },
                                  },
                                  "Item",
                                  _vm.$props,
                                  false
                                ),
                                _vm.$listeners
                              )
                            )
                          }
                        ),
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.item.__config__.jnpfKey === "table"
                  ? [
                      !_vm.item.__config__.noShow
                        ? _c(
                            "el-form-item",
                            { attrs: { "label-width": "0" } },
                            [
                              _vm.item.__config__.showTitle
                                ? _c(
                                    "div",
                                    { staticClass: "SNOWS-common-title" },
                                    [
                                      _c("h2", [
                                        _vm._v(
                                          _vm._s(_vm.item.__config__.label)
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "el-table",
                                {
                                  attrs: {
                                    data: _vm.item.__config__.defaultValue,
                                    "show-summary":
                                      !!_vm.item.__config__.defaultValue
                                        .length &&
                                      _vm.item["show-summary"] &&
                                      _vm.item.summaryField &&
                                      !!_vm.item.summaryField.length,
                                    "summary-method": _vm.getSummaries,
                                  },
                                },
                                [
                                  _vm._l(
                                    _vm.item.__config__.children,
                                    function (column, columnIndex) {
                                      return [
                                        !column.__config__.noShow &&
                                        (!column.__config__.visibility ||
                                          (Array.isArray(
                                            column.__config__.visibility
                                          ) &&
                                            column.__config__.visibility.includes(
                                              "pc"
                                            )))
                                          ? [
                                              column.__config__.jnpfKey ===
                                              "relationForm"
                                                ? _c("el-table-column", {
                                                    key: columnIndex,
                                                    attrs: {
                                                      prop: column.__vModel__,
                                                      label:
                                                        column.__config__.label,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              _c(
                                                                "el-link",
                                                                {
                                                                  attrs: {
                                                                    underline: false,
                                                                    type: "primary",
                                                                  },
                                                                  nativeOn: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.toTableDetail(
                                                                          column,
                                                                          scope
                                                                            .row[
                                                                            column.__vModel__ +
                                                                              "_id"
                                                                          ]
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                    " +
                                                                      _vm._s(
                                                                        scope
                                                                          .row[
                                                                          column
                                                                            .__vModel__
                                                                        ]
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  })
                                                : [
                                                    "relationFormAttr",
                                                    "popupAttr",
                                                  ].includes(
                                                    column.__config__.jnpfKey
                                                  )
                                                ? _c("el-table-column", {
                                                    key: columnIndex,
                                                    attrs: {
                                                      label:
                                                        column.__config__.label,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              _vm._v(
                                                                "\n                  " +
                                                                  _vm._s(
                                                                    scope.row[
                                                                      column.relationField.split(
                                                                        "_jnpfTable_"
                                                                      )[0] +
                                                                        "_" +
                                                                        column.showField
                                                                    ]
                                                                  ) +
                                                                  "\n                "
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  })
                                                : column.__config__.jnpfKey ===
                                                  "uploadFz"
                                                ? _c("el-table-column", {
                                                    key: columnIndex,
                                                    attrs: {
                                                      label:
                                                        column.__config__.label,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              _c(
                                                                "SNOWSUploadFz",
                                                                {
                                                                  attrs: {
                                                                    detailed:
                                                                      "",
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      scope.row[
                                                                        column
                                                                          .__vModel__
                                                                      ],
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          scope.row,
                                                                          column.__vModel__,
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "scope.row[column.__vModel__]",
                                                                  },
                                                                }
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  })
                                                : column.__config__.jnpfKey ===
                                                  "uploadImg"
                                                ? _c("el-table-column", {
                                                    key: columnIndex,
                                                    attrs: {
                                                      label:
                                                        column.__config__.label,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              _c(
                                                                "SNOWSUploadImg",
                                                                {
                                                                  attrs: {
                                                                    detailed:
                                                                      "",
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      scope.row[
                                                                        column
                                                                          .__vModel__
                                                                      ],
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          scope.row,
                                                                          column.__vModel__,
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "scope.row[column.__vModel__]",
                                                                  },
                                                                }
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  })
                                                : _c("el-table-column", {
                                                    key: columnIndex,
                                                    attrs: {
                                                      prop: column.__vModel__,
                                                      label:
                                                        column.__config__.label,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              column.__slot__ &&
                                                              column.__slot__
                                                                .prepend
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        column
                                                                          .__slot__
                                                                          .prepend
                                                                      )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row[
                                                                    column
                                                                      .__vModel__
                                                                  ]
                                                                )
                                                              ),
                                                              column.__slot__ &&
                                                              column.__slot__
                                                                .append
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        column
                                                                          .__slot__
                                                                          .append
                                                                      )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                            ]
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm.item.__config__.jnpfKey === "tab"
                  ? [
                      _c(
                        "el-tabs",
                        {
                          staticClass: "mb-10",
                          attrs: {
                            type: _vm.item.type,
                            "tab-position": _vm.item["tab-position"],
                          },
                          model: {
                            value: _vm.item.__config__.active,
                            callback: function ($$v) {
                              _vm.$set(_vm.item.__config__, "active", $$v)
                            },
                            expression: "item.__config__.active",
                          },
                        },
                        _vm._l(
                          _vm.item.__config__.children,
                          function (pane, i) {
                            return _c(
                              "el-tab-pane",
                              { key: i, attrs: { label: pane.title } },
                              _vm._l(
                                pane.__config__.children,
                                function (childItem, childIndex) {
                                  return _c(
                                    "Item",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          key: childIndex,
                                          attrs: { item: childItem },
                                        },
                                        "Item",
                                        _vm.$props,
                                        false
                                      ),
                                      _vm.$listeners
                                    )
                                  )
                                }
                              ),
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.item.__config__.jnpfKey === "collapse"
                  ? [
                      _c(
                        "el-collapse",
                        {
                          staticClass: "mb-20",
                          attrs: { accordion: _vm.item.accordion },
                          model: {
                            value: _vm.item.__config__.active,
                            callback: function ($$v) {
                              _vm.$set(_vm.item.__config__, "active", $$v)
                            },
                            expression: "item.__config__.active",
                          },
                        },
                        _vm._l(
                          _vm.item.__config__.children,
                          function (pane, i) {
                            return _c(
                              "el-collapse-item",
                              {
                                key: i,
                                attrs: { title: pane.title, name: pane.name },
                              },
                              _vm._l(
                                pane.__config__.children,
                                function (childItem, childIndex) {
                                  return _c(
                                    "Item",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          key: childIndex,
                                          attrs: { item: childItem },
                                        },
                                        "Item",
                                        _vm.$props,
                                        false
                                      ),
                                      _vm.$listeners
                                    )
                                  )
                                }
                              ),
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }