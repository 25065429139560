var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "child-table-column" },
    [
      !_vm.expand
        ? _vm._l(_vm.fewData, function (item, index) {
            return _c(
              "tr",
              { key: index, staticClass: "child-table__row" },
              _vm._l(_vm.head, function (headItem, i) {
                return _c(
                  "td",
                  {
                    key: i,
                    class: { "td-flex-1": !headItem.width },
                    style: { width: `${headItem.width - 1}px` },
                  },
                  [
                    _c("div", { staticClass: "cell" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(item[headItem.vModel]) +
                          "\n        "
                      ),
                    ]),
                  ]
                )
              }),
              0
            )
          })
        : _vm._e(),
      _vm.expand
        ? _vm._l(_vm.data, function (item, index) {
            return _c(
              "tr",
              { key: index, staticClass: "child-table__row" },
              _vm._l(_vm.head, function (headItem, i) {
                return _c(
                  "td",
                  {
                    key: i,
                    class: { "td-flex-1": !headItem.width },
                    style: { width: `${headItem.width - 1}px` },
                  },
                  [
                    _c("div", { staticClass: "cell" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(item[headItem.vModel]) +
                          "\n        "
                      ),
                    ]),
                  ]
                )
              }),
              0
            )
          })
        : _vm._e(),
      _vm.data.length > _vm.defaultNumber
        ? _c(
            "div",
            { staticClass: "expand-more-btn" },
            [
              _vm.expand
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: { click: _vm.toggleExpand },
                    },
                    [_vm._v("隐藏部分")]
                  )
                : _vm._e(),
              !_vm.expand
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: { click: _vm.toggleExpand },
                    },
                    [_vm._v("加载更多")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }