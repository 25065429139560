<template>
  <div class="SNOWS-common-layout">
    <div class="SNOWS-common-layout-left" v-if="columnData.type === 2">
      <div class="SNOWS-common-title" v-if="columnData.treeTitle">
        <h2>{{ columnData.treeTitle }}</h2>
      </div>
      <el-tree
        :data="treeData"
        :props="treeProps"
        default-expand-all
        highlight-current
        ref="treeBox"
        :expand-on-click-node="false"
        @node-click="handleNodeClick"
        class="SNOWS-common-el-tree"
        :node-key="treeProps.value"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <i :class="data.icon"></i>
          <span class="text">{{ node.label }}</span>
        </span>
      </el-tree>
    </div>
    <div class="SNOWS-common-layout-center">
      <Search
        ref="Search"
        :list="columnData.searchList"
        @reset="reset"
        @search="searchData"
      />
      <div class="SNOWS-common-layout-main SNOWS-flex-main">
        <div class="SNOWS-common-head">
          <div v-if="isPreview || !columnData.useBtnPermission">
            <el-button
              :type="i == 0 ? 'primary' : 'text'"
              :icon="item.icon"
              @click="headBtnsHandel(item.value)"
              v-for="(item, i) in columnData.btnsList"
              :key="i"
            >
              {{ item.label }}
            </el-button>
          </div>
          <div v-else>
            <el-button
              :type="i == 0 ? 'primary' : 'text'"
              :icon="item.icon"
              v-has="'btn_' + item.value"
              v-if="addShow"
              @click="headBtnsHandel(item.value)"
              v-for="(item, i) in columnData.btnsList"
              :key="i"
            >
              {{ item.label }}
            </el-button>
          </div>
          <div class="SNOWS-common-head-right">
            <!--            <el-tooltip content="高级查询" placement="top" v-if="columnData.hasSuperQuery">-->
            <!--              <el-link icon="icon-ym icon-ym-filter SNOWS-common-head-icon" :underline="false"-->
            <!--                @click="openSuperQuery()" />-->
            <!--            </el-tooltip>-->
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link
                icon="icon-ym icon-ym-Refresh SNOWS-common-head-icon"
                :underline="false"
                @click="initData()"
              />
            </el-tooltip>
          </div>
        </div>
        <SNOWS-table
          v-loading="listLoading"
          :data="list"
          row-key="id"
          size="medium"
          :default-expand-all="columnData.childTableStyle !== 2"
          :tree-props="{ children: 'children', hasChildren: '' }"
          @sort-change="sortChange"
          :row-style="rowStyle"
          :cell-style="cellStyle"
          :has-c="hasBatchBtn"
          @selection-change="handleSelectionChange"
          v-if="refreshTable"
          custom-column
          :span-method="arraySpanMethod"
          ref="tableRef"
          :hasNO="columnData.childTableStyle !== 2"
          :hasNOFixed="columnList.some((o) => o.fixed == 'left')"
        >
          <template v-if="columnData.type === 4">
            <template v-for="(item, i) in columnList">
              <el-table-column
                :prop="item.prop"
                :label="item.label"
                :align="item.align"
                :fixed="item.fixed != 'none' ? item.fixed : false"
                :width="item.width"
                :key="i"
                :sortable="item.sortable ? 'custom' : item.sortable"
                v-if="item.jnpfKey !== 'table'"
              >
                <template slot-scope="scope">
                  <template v-if="scope.row.rowEdit">
                    <template v-if="item.jnpfKey === 'numInput'">
                      <el-input-number
                        v-model="scope.row[item.prop]"
                        :placeholder="item.placeholder"
                        :min="item.min"
                        :max="item.max"
                        :step="item.step"
                        :precision="item.precision"
                        :controls-position="item['controls-position']"
                        :disabled="item.disabled"
                        style="width: 100%"
                      />
                    </template>
                    <template
                      v-else-if="['rate', 'slider'].includes(item.jnpfKey)"
                    >
                      <el-input-number
                        v-model="scope.row[item.prop]"
                        placeholder="请输入"
                        controls-position="right"
                        style="width: 100%"
                        :disabled="item.disabled"
                      />
                    </template>
                    <div
                      v-else-if="item.jnpfKey === 'switch'"
                      style="padding-top: 5px"
                    >
                      <el-switch
                        v-model="scope.row[item.prop]"
                        :active-value="item['active-value']"
                        :inactive-value="item['inactive-value']"
                        :disabled="item.disabled"
                      />
                    </div>
                    <template v-else-if="item.jnpfKey === 'time'">
                      <el-time-picker
                        v-model="scope.row[item.prop]"
                        style="width: 100%"
                        :picker-options="item['picker-options']"
                        :placeholder="item.placeholder"
                        :clearable="item.clearable"
                        :value-format="item['value-format']"
                        :format="item.format"
                        :readonly="item.readonly"
                        :disabled="item.disabled"
                      >
                      </el-time-picker>
                    </template>
                    <template v-else-if="['date'].includes(item.jnpfKey)">
                      <el-date-picker
                        v-model="scope.row[item.prop]"
                        :type="item.type || 'datetime'"
                        :clearable="item.clearable"
                        :placeholder="item.placeholder"
                        value-format="timestamp"
                        :format="item.format || 'yyyy-MM-dd HH:mm:ss'"
                        style="width: 100%"
                        :readonly="item.readonly"
                        :disabled="item.disabled"
                      >
                      </el-date-picker>
                    </template>
                    <template v-else-if="['comSelect'].includes(item.jnpfKey)">
                      <comSelect
                        v-model="scope.row[item.prop]"
                        :placeholder="item.placeholder"
                        :multiple="item.multiple"
                        :clearable="item.clearable"
                        :disabled="item.disabled"
                      />
                    </template>
                    <template v-else-if="['depSelect'].includes(item.jnpfKey)">
                      <depSelect
                        v-model="scope.row[item.prop]"
                        :placeholder="item.placeholder"
                        :selectType="item.selectType"
                        :ableDepIds="item.ableDepIds"
                        :multiple="item.multiple"
                        :clearable="item.clearable"
                        :disabled="item.disabled"
                      />
                    </template>
                    <template v-else-if="['userSelect'].includes(item.jnpfKey)">
                      <userSelect
                        v-model="scope.row[item.prop]"
                        :placeholder="item.placeholder"
                        :selectType="item.selectType"
                        :ableDepIds="item.ableDepIds"
                        :ablePosIds="item.ablePosIds"
                        :ableUserIds="item.ableUserIds"
                        :ableRoleIds="item.ableRoleIds"
                        :ableGroupIds="item.ableGroupIds"
                        :multiple="item.multiple"
                        :clearable="item.clearable"
                        :disabled="item.disabled"
                      />
                    </template>
                    <template
                      v-else-if="['usersSelect'].includes(item.jnpfKey)"
                    >
                      <usersSelect
                        v-model="scope.row[item.prop]"
                        :placeholder="item.placeholder"
                        :selectType="item.selectType"
                        :ableIds="item.ableIds"
                        :multiple="item.multiple"
                        :clearable="item.clearable"
                        :disabled="item.disabled"
                      />
                    </template>
                    <template v-else-if="['posSelect'].includes(item.jnpfKey)">
                      <posSelect
                        v-model="scope.row[item.prop]"
                        :placeholder="item.placeholder"
                        :selectType="item.selectType"
                        :ableDepIds="item.ableDepIds"
                        :ablePosIds="item.ablePosIds"
                        :multiple="item.multiple"
                        :clearable="item.clearable"
                        :disabled="item.disabled"
                      />
                    </template>
                    <template v-else-if="item.jnpfKey === 'groupSelect'">
                      <groupSelect
                        v-model="scope.row[item.prop]"
                        :placeholder="item.placeholder"
                        :multiple="item.multiple"
                        :clearable="item.clearable"
                        :disabled="item.disabled"
                      />
                    </template>
                    <template v-else-if="item.jnpfKey === 'roleSelect'">
                      <roleSelect
                        v-model="scope.row[item.prop]"
                        :placeholder="item.placeholder"
                        :multiple="item.multiple"
                        :clearable="item.clearable"
                        :disabled="item.disabled"
                      />
                    </template>
                    <template v-else-if="item.jnpfKey === 'address'">
                      <SNOWSAddress
                        v-model="scope.row[item.prop]"
                        :level="item.level"
                        :placeholder="item.placeholder"
                        :multiple="item.multiple"
                        :clearable="item.clearable"
                        :disabled="item.disabled"
                      />
                    </template>
                    <template
                      v-else-if="
                        ['select', 'radio', 'checkbox'].includes(item.jnpfKey)
                      "
                    >
                      <el-select
                        v-model="scope.row[item.prop]"
                        :placeholder="item.placeholder"
                        :filterable="item.filterable"
                        :multiple="item.multiple || item.jnpfKey === 'checkbox'"
                        :clearable="item.clearable"
                        :disabled="item.disabled"
                      >
                        <el-option
                          :label="oItem[item.__config__.props.label]"
                          v-for="(oItem, i) in item.__slot__.options"
                          :value="oItem[item.__config__.props.value]"
                          :key="i"
                        ></el-option>
                      </el-select>
                    </template>
                    <template v-else-if="item.jnpfKey === 'cascader'">
                      <el-cascader
                        v-model="scope.row[item.prop]"
                        :options="item.options"
                        :clearable="item.clearable"
                        :show-all-levels="item['show-all-levels']"
                        :props="item.props.props"
                        :filterable="item.filterable"
                        :separator="item.separator"
                        :placeholder="item.placeholder"
                        :disabled="item.disabled"
                        style="width: 100%"
                      >
                      </el-cascader>
                    </template>
                    <template v-else-if="item.jnpfKey === 'treeSelect'">
                      <SNOWS-TreeSelect
                        v-model="scope.row[item.prop]"
                        :options="item.options"
                        :props="item.props.props"
                        :placeholder="item.placeholder"
                        :multiple="item.multiple"
                        :clearable="item.clearable"
                        :disabled="item.disabled"
                      />
                    </template>
                    <template v-else-if="item.jnpfKey === 'relationForm'">
                      <relationForm
                        v-model="scope.row[item.prop]"
                        :placeholder="item.placeholder"
                        :modelId="item.modelId"
                        :columnOptions="item.columnOptions"
                        :relationField="item.relationField"
                        :hasPage="item.hasPage"
                        :pageSize="item.pageSize"
                        :clearable="item.clearable"
                        :disabled="item.disabled"
                      />
                    </template>
                    <template v-else-if="item.jnpfKey === 'popupSelect'">
                      <popupSelect
                        v-model="scope.row[item.prop]"
                        :placeholder="item.placeholder"
                        :interfaceId="item.interfaceId"
                        :columnOptions="item.columnOptions"
                        :propsValue="item.propsValue"
                        :relationField="item.relationField"
                        :hasPage="item.hasPage"
                        :pageSize="item.pageSize"
                        :popupType="item.popupType"
                        :popupTitle="item.popupTitle"
                        :popupWidth="item.popupWidth"
                        :clearable="item.clearable"
                        :disabled="item.disabled"
                      />
                    </template>
                    <template v-else-if="item.jnpfKey === 'popupTableSelect'">
                      <popupTableSelect
                        v-model="scope.row[item.prop]"
                        :placeholder="item.placeholder"
                        :interfaceId="item.interfaceId"
                        :columnOptions="item.columnOptions"
                        :propsValue="item.propsValue"
                        :relationField="item.relationField"
                        :hasPage="item.hasPage"
                        :pageSize="item.pageSize"
                        :popupType="item.popupType"
                        :popupTitle="item.popupTitle"
                        :popupWidth="item.popupWidth"
                        :filterable="item.filterable"
                        :multiple="item.multiple"
                        :clearable="item.clearable"
                        :disabled="item.disabled"
                      />
                    </template>
                    <template
                      v-else-if="
                        ['comInput', 'textarea'].includes(item.jnpfKey)
                      "
                    >
                      <el-input
                        v-model="scope.row[item.prop]"
                        :placeholder="item.placeholder"
                        :readonly="item.readonly"
                        :prefix-icon="item['prefix-icon']"
                        :suffix-icon="item['suffix-icon']"
                        :clearable="item.clearable"
                        :show-word-limit="item['show-word-limit']"
                        :maxlength="item.maxlength"
                        :show-password="item['show-password']"
                        :disabled="item.disabled"
                      >
                        <template
                          slot="prepend"
                          v-if="item.__slot__ && item.__slot__.prepend"
                        >{{ item.__slot__.prepend }}
                        </template>
                        <template
                          slot="append"
                          v-if="item.__slot__ && item.__slot__.append"
                        >{{ item.__slot__.append }}
                        </template>
                      </el-input>
                    </template>
                    <template
                      v-else-if="systemComponentsList.includes(item.jnpfKey)"
                    >
                      {{
                        scope.row[item.prop + "_name"] || scope.row[item.prop]
                      }}
                    </template>
                    <template v-else>
                      {{ scope.row[item.prop] }}
                    </template>
                  </template>
                  <template v-else>
                    {{ scope.row[item.prop + "_name"] || scope.row[item.prop] }}
                  </template>
                </template>
              </el-table-column>
            </template>
          </template>
          <template v-else>
            <template
              v-if="columnData.childTableStyle == 2 && childColumnList.length"
            >
              <el-table-column width="0"/>
              <el-table-column type="expand" width="40">
                <template slot-scope="scope">
                  <el-tabs>
                    <el-tab-pane
                      :label="child.label"
                      v-for="(child, cIndex) in childColumnList"
                      :key="cIndex"
                    >
                      <el-table :data="scope.row[child.prop]" size="mini">
                        <el-table-column
                          :prop="childTable.vModel"
                          :label="childTable.childLabel"
                          :align="childTable.align"
                          :width="childTable.width"
                          v-for="(childTable, iii) in child.children"
                          :key="iii"
                        />
                      </el-table>
                    </el-tab-pane>
                  </el-tabs>
                </template>
              </el-table-column>
              <el-table-column
                type="index"
                width="50"
                label="序号"
                align="center"
              />
            </template>
            <template v-for="(item, i) in columnList">
              <template v-if="item.jnpfKey === 'table'">
                <el-table-column
                  :prop="item.prop"
                  :label="item.label"
                  :align="item.align"
                  :key="i"
                  v-if="columnData.childTableStyle != 2"
                >
                  <el-table-column
                    :prop="child.prop"
                    :label="child.childLabel"
                    :align="child.align"
                    :width="child.width"
                    :key="ii"
                    :sortable="child.sortable ? 'custom' : child.sortable"
                    v-for="(child, ii) in item.children"
                    class-name="child-table-box"
                  >
                    <template slot-scope="scope">
                      <child-table-column
                        :data="scope.row[item.prop]"
                        :head="item.children"
                        @toggleExpand="
                          toggleExpand(scope.row, `${item.prop}Expand`)
                        "
                        :expand="scope.row[`${item.prop}Expand`]"
                        v-if="!ii"
                      />
                    </template>
                  </el-table-column>
                </el-table-column>
              </template>
              <el-table-column
                :prop="item.prop"
                :label="item.label"
                :align="item.align"
                :fixed="
                  columnList.some((o) => o.fixed == 'left') &&
                  i == 0 &&
                  columnData.groupField &&
                  columnData.type == 3
                    ? 'left'
                    : item.fixed != 'none' && columnData.childTableStyle != 2
                    ? item.fixed
                    : false
                "
                :width="item.width"
                :key="i"
                :sortable="item.sortable ? 'custom' : item.sortable"
                v-else
              />
            </template>
          </template>
          <el-table-column
            :fixed="
              columnList.some((o) => o.fixed == 'right') &&
              columnData.childTableStyle != 2
                ? 'right'
                : false
            "
            prop="flowState"
            label="状态"
            width="100"
            v-if="config.enableFlow == 1"
          >
            <template slot-scope="scope" v-if="!scope.row.top">
              <el-tag v-if="scope.row.flowState == 1">等待审核</el-tag>
              <el-tag type="success" v-else-if="scope.row.flowState == 2"
              >审核通过
              </el-tag
              >
              <el-tag type="danger" v-else-if="scope.row.flowState == 3"
              >审核退回
              </el-tag
              >
              <el-tag type="info" v-else-if="scope.row.flowState == 4"
              >流程撤回
              </el-tag
              >
              <el-tag type="info" v-else-if="scope.row.flowState == 5"
              >审核终止
              </el-tag
              >
              <el-tag type="warning" v-else>等待提交</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            :fixed="
              columnData.childTableStyle == 2 && childColumnList.length
                ? false
                : 'right'
            "
            :width="operationWidth"
            v-if="columnBtnsList.length || customBtnsList.length"
          >
            <template slot-scope="scope" v-if="!scope.row.top">
              <template v-if="scope.row.rowEdit">
                <el-button
                  size="mini"
                  type="text"
                  @click="saveForRowEdit(scope.row, 1)"
                >
                  保存
                </el-button>
                <el-button
                  size="mini"
                  type="text"
                  class="SNOWS-table-delBtn"
                  @click="cancelRowEdit(scope.row, scope.$index)"
                >取消
                </el-button>
                <el-button
                  size="mini"
                  type="text"
                  @click="submitForRowEdit(scope.row)"
                  v-if="config.enableFlow == 1"
                >提交
                </el-button>
              </template>
              <template v-else>
                <template v-if="isPreview || !columnData.useBtnPermission">
                  <template v-for="(item, i) in columnBtnsList">
                    <template v-if="item.value == 'edit'">
                      <template v-if="columnData.type === 4">
                        <!--                        <el-button size="mini" type="text" :key="i"-->
                        <!--                                   :disabled="config.enableFlow==1 && [1,2,4,5].indexOf(scope.row.flowState)>-1"-->
                        <!--                                   @click="scope.row.rowEdit=true">-->
                        <!--                          {{ item.label }}-->
                        <!--                        </el-button>-->
                        <el-button
                          size="mini"
                          type="text"
                          :key="i"
                          :disabled="
                            config.enableFlow == 1 &&
                            [1, 2, 4, 5].indexOf(scope.row.flowState) > -1
                          "
                          @click="hanLinEdit(scope.row)"
                        >
                          {{ item.label }}
                        </el-button>
                      </template>
                      <template v-else>
                        <el-button
                          size="mini"
                          type="text"
                          :key="i"
                          :disabled="
                            config.enableFlow == 1 &&
                            [1, 2, 4, 5].indexOf(scope.row.flowState) > -1
                          "
                          @click="columnBtnsHandel(item.value, scope.row)"
                        >
                          {{ item.label }}
                        </el-button>
                      </template>
                    </template>
                    <template v-else-if="item.value == 'remove'">
                      <el-button
                        size="mini"
                        type="text"
                        :key="i"
                        class="SNOWS-table-delBtn"
                        :disabled="
                          config.enableFlow == 1 &&
                          [1, 2, 3, 5].indexOf(scope.row.flowState) > -1
                        "
                        @click="
                          columnBtnsHandel(item.value, scope.row, scope.$index)
                        "
                      >
                        {{ item.label }}
                      </el-button>
                    </template>
                    <template v-else-if="item.value == 'detail'">
                      <el-button
                        size="mini"
                        type="text"
                        :key="i"
                        :disabled="
                          config.enableFlow == 1 && !scope.row.flowState
                        "
                        @click="columnBtnsHandel(item.value, scope.row)"
                        v-if="scope.row.id"
                      >
                        {{ item.label }}
                      </el-button>
                    </template>
                    <template v-else>
                      <el-button
                        size="mini"
                        type="text"
                        :key="i"
                        @click="customBtnsHandel(item, scope.row, scope.$index)"
                      >
                        {{ item.label }}
                      </el-button>
                    </template>
                  </template>
                  <template v-if="customBtnsList.length">
                    <el-dropdown hide-on-click>
                      <span class="el-dropdown-link">
                        <el-button type="text" size="mini">
                          {{
                            $t("cip.plat.wfDesign.model.btn.moreBtn")
                          }}<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          v-for="(item, i) in customBtnsList"
                          :key="i"
                          @click.native="
                            customBtnsHandel(item, scope.row, scope.$index)
                          "
                        >
                          {{ item.label }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                </template>
                <template v-else>
                  <template v-for="(item, i) in columnBtnsList">
                    <template v-if="item.value == 'edit'">
                      <template v-if="columnData.type === 4">
                        <el-button
                          size="mini"
                          type="text"
                          :key="i"
                          :disabled="
                            config.enableFlow == 1 &&
                            [1, 2, 4, 5].indexOf(scope.row.flowState) > -1
                          "
                          @click="scope.row.rowEdit = true"
                          v-has="'btn_' + item.value"
                        >
                          {{ item.label }}
                        </el-button>
                      </template>
                      <template v-else>
                        <el-button
                          size="mini"
                          type="text"
                          :key="i"
                          v-if="scope.row.btnShow"
                          :disabled="
                            config.enableFlow == 1 &&
                            [1, 2, 4, 5].indexOf(scope.row.flowState) > -1
                          "
                          @click="columnBtnsHandel(item.value, scope.row)"
                          v-has="'btn_' + item.value"
                        >
                          {{ item.label }}
                        </el-button>
                      </template>
                    </template>
                    <template v-else-if="item.value == 'remove'">
                      <el-button
                        size="mini"
                        type="text"
                        :key="i"
                        v-if="scope.row.btnShow"
                        class="SNOWS-table-delBtn"
                        :disabled="
                          config.enableFlow == 1 &&
                          [1, 2, 3, 5].indexOf(scope.row.flowState) > -1
                        "
                        @click="
                          columnBtnsHandel(item.value, scope.row, scope.$index)
                        "
                        v-has="'btn_' + item.value"
                      >{{ item.label }}
                      </el-button>
                    </template>
                    <template v-else-if="item.value == 'detail'">
                      <el-button
                        size="mini"
                        type="text"
                        :key="i"
                        :disabled="
                          config.enableFlow == 1 && !scope.row.flowState
                        "
                        @click="columnBtnsHandel(item.value, scope.row)"
                        v-has="'btn_' + item.value"
                      >
                        {{ item.label }}
                      </el-button>
                    </template>
                    <template v-else>
                      <el-button
                        size="mini"
                        type="text"
                        :key="i"
                        v-has="item.value"
                        @click="customBtnsHandel(item, scope.row, scope.$index)"
                      >{{ item.label }}
                      </el-button>
                    </template>
                  </template>
                  <template v-if="customBtnsList.length">
                    <el-dropdown hide-on-click>
                      <span class="el-dropdown-link">
                        <el-button type="text" size="mini">
                          更多
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          v-for="(item, i) in customBtnsList"
                          :key="i"
                          @click.native="
                            customBtnsHandel(item, scope.row, scope.$index)
                          "
                          v-has="item.value"
                        >{{ item.label }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                </template>
              </template>
            </template>
          </el-table-column>
        </SNOWS-table>
        <template
          v-if="columnData.type !== 3 && columnData.hasPage && refreshTable"
        >
          <pagination
            :total="total"
            :page.sync="listQuery.currentPage"
            :limit.sync="listQuery.pageSize"
            @pagination="initData"
          />
        </template>
      </div>
    </div>
    <FlowBox v-if="flowVisible" ref="FlowBox" @close="closeFlow"/>
    <Form v-show="formVisible" ref="Form" @refreshDataList="refresh"/>
    <Detail
      v-show="detailVisible"
      ref="Detail"
      @close="detailVisible = false"
    />
    <ExportBox v-if="exportBoxVisible" ref="ExportBox" @download="download"/>
    <ImportBox v-if="uploadBoxVisible" ref="UploadBox" @refresh="initData"/>
    <SuperQuery
      v-if="superQueryVisible"
      ref="SuperQuery"
      :columnOptions="columnOptions"
      @superQuery="superQuery"
    />
    <candidate-form
      :visible.sync="candidateVisible"
      :candidateList="candidateList"
      :branchList="branchList"
      taskId="0"
      :formData="workFlowFormData"
      @submitCandidate="submitCandidate"
      :isCustomCopy="isCustomCopy"
    />
  </div>
</template>

<script>
import {
  getModelList,
  deleteModel,
  batchDelete,
  exportModel,
  createModel,
  updateModel,
  getPostList,
  getUserSelect,
  getRoleList,
} from "@/api/onlineDev/visualDev";
import {Create, Update} from "@/api/workFlow/workFlowForm";
import {getDataInterfaceDataInfoByIds, getDataInterfaceRes} from "@/api/systemData/dataInterface";
import {getColumnsByModuleId} from "@/api/common";
import {
  dyOptionsList,
  systemComponentsList,
} from "@/components/Generator/generator/comConfig";
import {Candidates} from "@/api/workFlow/FlowBefore";
import request from "@/router/axios";
import Form from "./Form";
import FlowBox from "@/views/workFlow/components/FlowBox";
import Detail from "./detail";
import ExportBox from "@/components/ExportBox";
import Search from "./Search";
import ChildTableColumn from "./child-table-column";
import SuperQuery from "@/components/SuperQuery";
import CandidateForm from "@/views/workFlow/components/CandidateForm";
import {getChildList} from "@/api/system/dictbiz";
import {mapGetters} from "vuex";

export default {
  name: "dynamicModel",
  components: {
    Form,
    ExportBox,
    Search,
    Detail,
    FlowBox,
    ChildTableColumn,
    SuperQuery,
    CandidateForm,
  },
  props: ["config", "modelId", "isPreview"],
  data() {
    return {
      systemComponentsList,
      keyword: "",
      treeProps: {
        children: "children",
        label: "fullName",
        value: "id",
      },
      list: [],
      cacheList: [],
      total: 0,
      listLoading: false,
      listQuery: {
        currentPage: 1,
        pageSize: 20,
        sort: "desc",
        sidx: "",
        menuId: "",
        queryJson: "",
        superQueryJson: "",
        type: "page",
      },
      defaultListQuery: {
        pageSize: 20,
        sort: "desc",
        sidx: "",
      },
      flowVisible: false,
      formVisible: false,
      detailVisible: false,
      importBoxVisible: false,
      exportBoxVisible: false,
      uploadBoxVisible: false,
      superQueryVisible: false,
      treeData: [],
      dataList: [],
      dataListBck: [],
      dataTotal: 0,
      treeActiveId: "",
      columnData: {
        columnBtnsList: [],
      },
      formData: {},
      columnList: [],
      childColumnList: [],
      columnOptions: [],
      exportList: [],
      columnBtnsList: [],
      customBtnsList: [],
      hasBatchBtn: false,
      refreshTable: false,
      multipleSelection: [],
      settingsColumnList: [],
      mergeList: [],
      expandObj: {},
      flowTemplateJson: {},
      isCustomCopy: false,
      candidateVisible: false,
      candidateType: 1,
      branchList: [],
      candidateList: [],
      currRow: {},
      workFlowFormData: {},
      rowStyle: null,
      cellStyle: null,
      modelId: "",
      type: "",
      // posData: [],
      // userData: [],
      // roleData: [],
      fieldObject: {},
      addShow: false
    };
  },
  computed: {
    ...mapGetters(['userInfo', 'visualDevData', 'posData', 'roleData', 'userData']),
    operationWidth() {
      const customWidth = this.customBtnsList.length ? 50 : 0;
      return this.columnBtnsList.length * 50 + customWidth;
    },
  },
  created() {

    this.init();
  },
  methods: {
    async init() {
      this.listLoading = true;
      this.visualDevData.forEach((item) => {
        if (item.fullName == this.$route.name) {
          this.modelId = item.id;
          this.type = item.type;
        }
      });
      this.listQuery.menuId = this.modelId;
      this.refreshTable = false;
      if (!this.config.columnData || !this.config.formData) return;
      this.columnData = JSON.parse(this.config.columnData);
      if (this.columnData.useBtnPermission == true) {
        this.columnData.columnBtnsList.forEach((item) => {
          if (item.func != "" && item.func) {
            item.func = JSON.parse(item.func)
            this.fieldObject = item.func;
          }
        })
      }
      if (this.columnData.type === 3) {
        this.columnData.columnList = this.columnData.columnList.filter(
          (o) => o.prop != this.columnData.groupField
        );
      }
      if (this.config.enableFlow == 1) {
        this.flowTemplateJson = this.config.flowTemplateJson
          ? JSON.parse(this.config.flowTemplateJson)
          : {};
        this.isCustomCopy =
          this.flowTemplateJson.properties &&
          this.flowTemplateJson.properties.isCustomCopy;
      }
      this.hasBatchBtn = this.columnData.btnsList.some(
        (o) => o.value == "batchRemove"
      );
      this.formData = JSON.parse(this.config.formData);
      this.customBtnsList = this.columnData.customBtnsList || [];
      this.columnBtnsList = this.columnData.columnBtnsList || [];
      this.columnOptions = this.columnData.columnOptions || [];
      this.listLoading = true;
      if (this.isPreview) this.listQuery.menuId = "270579315303777093";
      // let res = await getColumnsByModuleId(this.listQuery.menuId)
      // this.settingsColumnList = res.data || []
      this.settingsColumnList = [];
      this.rowStyle = this.sinoma.getScriptFunc.call(
        this,
        this.columnData.funcs &&
        this.columnData.funcs.rowStyle &&
        this.columnData.funcs.rowStyle.func
      );
      this.cellStyle = this.sinoma.getScriptFunc.call(
        this,
        this.columnData.funcs &&
        this.columnData.funcs.cellStyle &&
        this.columnData.funcs.cellStyle.func
      );
      this.getColumnList();
      this.$nextTick(() => {
        this.refreshTable = true;
      });
      if (this.columnData.type === 4) this.buildOptions();
      if (this.isPreview) return (this.listLoading = false);
      this.listQuery.pageSize = this.columnData.pageSize;
      this.listQuery.sort = this.columnData.sort;
      this.listQuery.sidx = this.columnData.defaultSidx;
      this.defaultListQuery.pageSize = this.columnData.pageSize;
      this.defaultListQuery.sort = this.columnData.sort;
      this.defaultListQuery.sidx = this.columnData.defaultSidx;
      if (this.columnData.type === 3 || !this.columnData.hasPage)
        this.listQuery.pageSize = 10000;
      if (this.columnData.type === 2) {
        this.treeProps.value = this.columnData.treePropsValue || "id";
        this.treeProps.label = this.columnData.treePropsLabel || "fullName";
        this.treeProps.children =
          this.columnData.treePropsChildren || "children";
        this.getTreeView();
      } else {
        this.initData();
      }
    },
    fnTree(arr) {
      let data = JSON.parse(JSON.stringify(arr));
      let newData = [];
      const callback = (item) => {
        (item.children || (item.children = [])).map((v) => {
          callback(v);
        });
        delete item.children;
        newData.push(item);
      };
      data.map((v) => callback(v));
      return newData;
    },
    initData() {
      // 岗位数据
      // getPostList(1, 500).then((res) => {
      //   this.posData = res.data.data.records;
      // })
      // 角色数据
      // getRoleList(1, 500).then((res) => {
      //   this.roleData = res.data.data.records;
      // });
      // 用户数据
      // getUserSelect(1, 500).then((res) => {
      //   this.userData = res.data.data.records;
      //   this.$store.commit('SET_USERDATA', this.userData)
        if (this.isPreview) return;
        this.listLoading = true;
        getModelList(this.modelId, this.listQuery).then((res) => {
          this.dataTotal = res.data.data.pagination.total[0].total;
          this.dataList = res.data.data.list;
          this.dataList.forEach((e) => {
            if ((e[this.fieldObject.field] == 0 || e[this.fieldObject.field] == '') && this.userInfo.user_id == e[this.fieldObject.create] && this.columnData.useBtnPermission == true) {
              e.btnShow = true;
            } else {
              e.btnShow = false;
            }
          })
          if (this.userInfo.dept_id.indexOf(this.fieldObject.dept_id) != -1 && this.columnData.useBtnPermission == true) {
            this.addShow = true;
          } else {
            this.addShow = false;
          }
          this.dataListBck = JSON.parse(JSON.stringify(res.data.data.list));
          let columnList = this.columnData.columnList;
          const p1 = new Promise((resolve, reject) => {
            columnList.forEach((item) => {
              //组织
              if (item.jnpfKey == "comSelect") {
                // 子表
                if (item.prop.startsWith("tableField")) {
                  let prop = item.prop
                  let propName = prop.split('-')[1]
                  this.dataList.forEach(t => {
                    let data = t[prop.split('-')[0]]
                    data.forEach(item => {
                      let comData = this.fnTree(this.$store.getters.deptTree);
                      let comList = comData.filter((com) => {
                        return JSON.stringify(com.organizeIds) == item[propName];
                      });
                      item[propName] = comList[0].organize;
                    })
                  })
                  return;
                }
                this.dataList.forEach((items) => {
                  if (items.hasOwnProperty(item.prop)) {
                    let comData = this.fnTree(this.$store.getters.deptTree);
                    let comList = comData.filter((com) => {
                      return JSON.stringify(com.organizeIds) == items[item.prop];
                    });
                    items[item.prop] = comList[0].organize;
                  }
                });
              }
              //部门
              if (item.jnpfKey == "depSelect") {
                //子表
                if (item.prop.startsWith("tableField")) {
                  let prop = item.prop
                  let propName = prop.split('-')[1]
                  this.dataList.forEach(t => {
                    let data = t[prop.split('-')[0]]
                    data.forEach(item => {
                      let deptData = this.fnTree(this.$store.getters.deptTree);
                      let deptList = deptData.filter((dept) => {
                        return dept.id == item[propName];
                      });
                      item[propName] = deptList[0].fullName;
                    })
                  })
                  return
                }
                this.dataList.forEach((items) => {
                  if (items.hasOwnProperty(item.prop)) {
                    let deptData = this.fnTree(this.$store.getters.deptTree);
                    let deptList = deptData.filter((dept) => {
                      return dept.id == items[item.prop];
                    });
                    items[item.prop] = deptList[0].fullName;
                  }
                });
              }
              //岗位
              if (item.jnpfKey == "posSelect") {
                //子表
                if (item.prop.startsWith("tableField")) {
                  let prop = item.prop
                  let propName = prop.split('-')[1];
                  this.dataList.forEach(t => {
                    let data = t[prop.split('-')[0]]
                    data.forEach(item => {
                      let posList = this.posData.filter((pos) => {
                        return pos.id = item[propName]
                      })
                      item[propName] = posList[0].postName;
                    })
                  })
                  return
                }
                this.dataList.forEach((items) => {
                  if (items.hasOwnProperty(item.prop)) {
                    let posData = res.data.data.records;
                    let posList = this.posData.filter((pos) => {
                      return pos.id == items[item.prop];
                    });
                    items[item.prop] = posList[0].postName;
                  }
                });
              }
              //用户
              if (item.jnpfKey == "userSelect") {
                // 子表
                if (item.prop.startsWith("tableField")) {
                  let prop = item.prop;
                  let propName = prop.split('-')[1];
                  this.dataList.forEach(t => {
                    let data = t[prop.split('-')[0]]
                    if (item.multiple == true) {
                      data.forEach((s) => {
                        let datauser = new Array();
                        s[propName] = JSON.parse(s[propName]);
                        s[propName].forEach((i) => {
                          this.userData.forEach((u) => {
                            if (i == u.id) {
                              datauser.push(u.realName);
                            }
                          })
                        })
                        s[propName] = datauser.join()
                      })
                    } else {
                      data.forEach(item => {
                        if (item[propName] == '') {
                          item[propName] = '';
                          return;
                        }
                        let userList = this.userData.filter((user) => {
                          return user.id = item[propName]
                        })
                        item[propName] = userList[0].realName;
                      })
                    }
                  })
                  return;
                }
                this.dataList.forEach((items) => {
                  if (items.hasOwnProperty(item.prop)) {
                    if (item.multiple == true) {
                      if (items[item.prop] != '') {
                        items[item.prop] = JSON.parse(items[item.prop]);
                        let data = new Array();
                        items[item.prop].forEach((i) => {
                          this.userData.forEach((u) => {
                            if (i == u.id) {
                              data.push(u.realName + ",");
                            }
                          })
                        })
                        data[data.length - 1] = data[data.length - 1].slice(0, data[data.length - 1].lastIndexOf(','))
                        items[item.prop] = data;
                      }
                    } else {
                      this.userData.forEach((user) => {
                        if (items[item.prop] == '') {
                          items[item.prop] = '';
                          return;
                        }
                        if (items[item.prop] == user.id) {
                          items[item.prop] = user.realName;
                        }
                      });
                    }
                  }
                });
              }
              //角色
              if (item.jnpfKey == "roleSelect") {
                // 子表
                if (item.prop.startsWith("tableField")) {
                  let prop = item.prop;
                  let propName = prop.split('-')[1];
                  this.dataList.forEach(t => {
                    let data = t[prop.split('-')[0]]
                    data.forEach(item => {
                      let roleList = this.roleData.filter((role) => {
                        return role.id = item[propName]
                      })
                      item[propName] = roleList[0].roleName;
                    })
                  })
                  return;
                }
                this.dataList.forEach((items) => {
                  if (items.hasOwnProperty(item.prop)) {
                    this.roleData.forEach((role) => {
                      if (items[item.prop] == role.id) {
                        items[item.prop] = role.roleName;
                      }
                    });
                  }
                });
              }
              //数字输入框
              if (item.jnpfKey == "numInput") {
                this.dataList.forEach((items) => {
                  if (items.hasOwnProperty(item.prop)) {
                    items[item.prop] = Number(items[item.prop]);
                  }
                });
              }
              //switch开关
              if (item.jnpfKey == "switch") {
                // 子表
                if (item.prop.startsWith("tableField")) {
                  let prop = item.prop;
                  let propName = prop.split('-')[1];
                  this.dataList.forEach(t => {
                    let data = t[prop.split('-')[0]]
                    data.forEach(switchs => {
                      if (switchs[propName] == item["active-value"]) {
                        switchs[propName] = item.activeTxt;
                      } else if (switchs[propName] == item["inactive-value"]) {
                        switchs[propName] = item.inactiveTxt;
                      }
                    })
                  })
                  return;
                }
                this.dataList.forEach((items) => {
                  if (items.hasOwnProperty(item.prop)) {
                    if (items[item.prop] == item["active-value"]) {
                      items[item.prop] = item.activeTxt;
                    } else if (items[item.prop] == item["inactive-value"]) {
                      items[item.prop] = item.inactiveTxt;
                    }
                  }
                });
              }
              //checkbox选择
              if (item.jnpfKey == "checkbox") {
                this.dataList.forEach((items) => {
                  if (items.hasOwnProperty(item.prop)) {
                    items[item.prop] = JSON.parse(items[item.prop]);
                    let checkList = new Array();
                    items[item.prop].forEach((check) => {
                      item.__slot__.options.forEach((checks) => {
                        if (check == checks[item.__config__.props.value]) {
                          checkList.push(checks[item.__config__.props.label] + ",");
                        }
                      });
                    });
                    checkList[checkList.length - 1] = checkList[checkList.length - 1].slice(0, checkList[checkList.length - 1].lastIndexOf(','))
                    items[item.prop] = checkList;
                  }
                });
              }
              //radio
              if (item.jnpfKey == "radio") {
                this.dataList.forEach((items) => {
                  if (items.hasOwnProperty(item.prop)) {
                    item.__slot__.options.forEach((radio) => {
                      if (radio[item.__config__.props.value] == items[item.prop]) {
                        items[item.prop] = radio[item.__config__.props.label];
                      }
                    });
                  }
                });
              }
              //select
              if (item.jnpfKey == "select") {
                // 子表
                if (item.prop.startsWith("tableField")) {
                  let prop = item.prop;
                  let propName = prop.split('-')[1];
                  this.dataList.forEach(t => {
                    let data = t[prop.split('-')[0]]
                    if (data != undefined) {
                      data.forEach(selects => {
                        if (selects[propName] != '') {
                          selects[propName] = JSON.parse(selects[propName]);
                          if (selects[propName].length > 0) {
                            let result = new Array();
                            item.__slot__.options.forEach((radio) => {
                              selects[propName].forEach((i) => {
                                if (radio[item.__config__.props.value] == i) {
                                  result.push(radio[item.__config__.props.label])
                                }
                              })
                            });
                            selects[propName] = result.join(',')
                          } else {
                            item.__slot__.options.forEach((options) => {
                              if (options[item.__config__.props.value] == selects[propName]) {
                                selects[propName] = options[item.__config__.props.label];
                              }
                            });
                          }
                        }
                      })
                    }
                  })
                  return;
                }
                this.dataList.forEach((items) => {
                  if (items.hasOwnProperty(item.prop)) {
                    items[item.prop] = JSON.parse(items[item.prop]);
                    if (items[item.prop].length > 0) {
                      let result = new Array();
                      item.__slot__.options.forEach((radio) => {
                        items[item.prop].forEach((i) => {
                          if (radio[item.__config__.props.value] == i) {
                            result.push(radio[item.__config__.props.label])
                          }
                        })
                      });
                      items[item.prop] = result.join(',')
                    } else {
                      item.__slot__.options.forEach((radio) => {
                        if (radio[item.__config__.props.value] == items[item.prop]) {
                          items[item.prop] = radio[item.__config__.props.label];
                        }
                      });
                    }
                  }
                });
              }
              // cascader
              if (item.jnpfKey == "cascader") {
                // 子表
                if (item.prop.startsWith("tableField")) {
                  let prop = item.prop;
                  let propName = prop.split('-')[1];
                  let cascaderOptions = this.fnTree(item.options);
                  this.dataList.forEach(t => {
                    let data = t[prop.split('-')[0]]
                    data.forEach((a) => {
                      a[propName] = JSON.parse(a[propName])
                      if (a[propName].length) {
                        for (let i = 0; i <= cascaderOptions.length; i++) {
                          for (let caser = 0; caser <= a[propName].length; caser++) {
                            if (cascaderOptions[i] != undefined) {
                              if (a[propName][caser] == cascaderOptions[i].id) {
                                a[propName][caser] = cascaderOptions[i][item.props.props.label]
                              }
                            }
                          }
                        }
                      }
                      a[propName] = a[propName].toString().replace(/,/gi, '/')
                    })
                  })
                  return;
                }
                this.dataList.forEach((items) => {
                  if (items.hasOwnProperty(item.prop)) {
                    items[item.prop] = JSON.parse(items[item.prop]);
                    let cascadersList = new Array();
                    let dataList = this.fnTree(item.options);
                    items[item.prop].forEach((cascader) => {
                      dataList.forEach((cascaders) => {
                        if (cascader == cascaders[item.props.props.value]) {
                          cascadersList.push(cascaders[item.props.props.label] + '/');
                        }
                      });
                    });
                    cascadersList[cascadersList.length - 1] = cascadersList[cascadersList.length - 1].substring(0, cascadersList[cascadersList.length - 1].length - 1);
                    items[item.prop] = cascadersList;
                  }
                });
              }
              // treeSelect
              if (item.jnpfKey == 'treeSelect') {
                // 子表
                if (item.prop.startsWith("tableField")) {
                  let prop = item.prop;
                  let propName = prop.split('-')[1];
                  let treeSelectOptions = this.fnTree(item.options);
                  this.dataList.forEach((t) => {
                    let data = t[prop.split('-')[0]];
                    data.forEach((datas) => {
                      treeSelectOptions.forEach((tre) => {
                        if (tre[item.props.props.value] == datas[propName]) {
                          datas[propName] = tre[item.props.props.label]
                        }
                      })
                    })
                  })
                  return
                }
                this.dataList.forEach((items) => {
                  if (items.hasOwnProperty(item.prop)) {
                    let dataList = this.fnTree(item.options);
                    dataList.forEach((treeSelect) => {
                      if (treeSelect[item.props.props.value] == items[item.prop]) {
                        items[item.prop] = treeSelect[item.props.props.label]
                      }
                    })
                  }
                })
              }
              // date
              if (item.jnpfKey == 'date') {
                // 子表
                if (item.prop.startsWith("tableField")) {
                  let prop = item.prop;
                  let propName = prop.split('-')[1];
                  this.dataList.forEach(t => {
                    let data = t[prop.split('-')[0]]
                    data.forEach(dates => {
                      dates[propName] = Number(dates[propName]);
                      dates[propName] = this.shijianc(dates[propName]);
                    })
                  })
                  return;
                }
              }
              // 创建人员
              if (item.jnpfKey == 'createUser') {
                this.dataList.forEach((items) => {
                  if (items.hasOwnProperty(item.prop)) {
                    this.userData.forEach((user) => {
                      if (items[item.prop] == user.id) {
                        items[item.prop] = user.realName;
                      }
                    });
                  }
                });
              }
              // 修改人员
              if (item.jnpfKey == 'modifyUser') {
                this.dataList.forEach((items) => {
                  if (items.hasOwnProperty(item.prop)) {
                    this.userData.forEach((user) => {
                      if (items[item.prop] == user.id) {
                        items[item.prop] = user.realName;
                      }
                    });
                  }
                });
              }
              // 下拉表格
              if (item.jnpfKey == 'popupSelect') {
                // 子表
                if (item.prop.startsWith("tableField")) {
                  let prop = item.prop;
                  let propName = prop.split('-')[1];
                  this.dataList.forEach((t) => {
                    let data = t[prop.split('-')[0]]
                    data.forEach(dates => {
                      let modelId = item.interfaceId;
                      let query = {
                        ids: [dates[propName]],
                        interfaceId: item.interfaceId,
                        propsValue: item.propsValue,
                        relationField: item.relationField,
                        paramList: []
                      };
                      getDataInterfaceDataInfoByIds(modelId, query).then(res => {
                        let dataList = res.data.data;
                        if (dataList.length > 0) {
                          dates[propName] = dataList[0].fullName
                        }
                      })
                    })
                  })
                  return
                }
                this.dataList.forEach((items) => {
                  if (items.hasOwnProperty(item.prop)) {
                    let modelId = item.interfaceId;
                    let query = {
                      ids: [items[item.prop]],
                      interfaceId: item.interfaceId,
                      propsValue: item.propsValue,
                      relationField: item.relationField,
                      paramList: []
                    };
                    getDataInterfaceDataInfoByIds(modelId, query).then(res => {
                      let data = res.data.data;
                      if (data.length > 0) {
                        items[item.prop] = data[0].fullName
                      }
                    })
                  }
                });
              }
            });
            resolve()
          })
          setTimeout(() => {
            Promise.all([p1]).then((res) => {
              this.setColumnData();
            })
          }, 1000)
        });
      // });
    },
    shijianc(time) {
      var date = new Date(time)
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      return Y + M + D
    },
    setColumnData() {
      if (this.columnData.type === 4) {
        this.list = this.dataList.map((o) => ({
          ...o,
          ...this.expandObj,
          rowEdit: false,
        }));
        this.cacheList = JSON.parse(JSON.stringify(this.list));
      } else {
        this.list = this.dataList.map((o) => ({
          ...o,
          ...this.expandObj,
        }));
      }
      if (this.columnData.type !== 3 && this.columnData.hasPage)
        this.total = this.dataTotal;
      this.listLoading = false;
      this.$nextTick(() => {
        if (
          this.columnData.funcs &&
          this.columnData.funcs.afterOnload &&
          this.columnData.funcs.afterOnload.func
        )
          this.setTableLoadFunc();
      });
    },
    getTreeView() {
      if (this.columnData.treeDataSource === "dictionary") {
        if (!this.columnData.treeDictionary) return;
        getChildList(1, 500, this.columnData.treeDictionary).then((res) => {
          let data = res.data.data;
          data.forEach((item) => {
            item.fullName = item.dictValue;
            item.enCode = item.dictKey;
          });
          this.treeData = data;
          this.initData();
        });
      }
      if (this.columnData.treeDataSource === "organize") {
        this.treeData = this.$store.getters.deptTree;
        this.initData();
      }
      if (this.columnData.treeDataSource === "department") {
        this.treeData = this.$store.getters.deptTree;
        this.initData();
      }
      if (this.columnData.treeDataSource === "api") {
        if (!this.columnData.treePropsUrl) return;
        let query = {
          paramList: [],
          tenantId: "000000",
          origin: "preview",
        };
        getDataInterfaceRes(this.columnData.treePropsUrl, query).then((res) => {
          let data = res.data.data;
          this.treeData = data;
          this.initData();
        });
      }
    },
    getColumnList() {
      if (this.isPreview) {
        const columnList = this.columnData.columnList;
        this.columnList = this.transformColumnList(columnList);
        return;
      }
      let columnPermissionList = [];
      if (!this.columnData.useColumnPermission) {
        columnPermissionList = this.columnData.columnList;
      } else {
        const permissionList = this.$store.getters.permissionList;
        const modelId = this.modelId;
        const list = permissionList.filter((o) => o.modelId === modelId);
        const columnList = list[0] && list[0].column ? list[0].column : [];
        for (let i = 0; i < this.columnData.columnList.length; i++) {
          inner: for (let j = 0; j < columnList.length; j++) {
            if (this.columnData.columnList[i].prop === columnList[j].enCode) {
              columnPermissionList.push(this.columnData.columnList[i]);
              break inner;
            }
          }
        }
      }
      this.columnList = this.transformColumnList(columnPermissionList);
    },
    transformColumnList(columnList) {
      let list = [];
      for (let i = 0; i < columnList.length; i++) {
        const e = columnList[i];
        if (!e.prop.includes("-")) {
          list.push(e);
        } else {
          let prop = e.prop.split("-")[0];
          let vModel = e.prop.split("-")[1];
          let label = e.label.split("-")[0];
          let childLabel = e.label.replace(label + "-", "");
          let newItem = {
            align: "center",
            jnpfKey: "table",
            prop,
            label,
            children: [],
          };
          e.vModel = vModel;
          e.childLabel = childLabel;
          if (!this.expandObj.hasOwnProperty(`${prop}Expand`))
            this.$set(this.expandObj, `${prop}Expand`, false);
          if (!list.some((o) => o.prop === prop)) list.push(newItem);
          for (let i = 0; i < list.length; i++) {
            if (list[i].prop === prop) {
              list[i].children.push(e);
              break;
            }
          }
        }
      }
      this.getMergeList(list);
      this.getExportList(list);
      this.childColumnList = list.filter((o) => o.jnpfKey === "table");
      return list;
    },
    getExportList(list) {
      let exportList = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i].jnpfKey === "table") {
          for (let j = 0; j < list[i].children.length; j++) {
            exportList.push(list[i].children[j]);
          }
        } else {
          exportList.push(list[i]);
        }
      }
      this.exportList = exportList;
    },
    getMergeList(list) {
      list.forEach((item) => {
        if (item.children && item.children.length > 0) {
          item.children.forEach((child, index) => {
            if (index == 0) {
              this.mergeList.push({
                prop: child.prop,
                rowspan: 1,
                colspan: item.children.length,
              });
            } else {
              this.mergeList.push({
                prop: child.prop,
                rowspan: 0,
                colspan: 0,
              });
            }
          });
        } else {
          this.mergeList.push({
            prop: item.prop,
            rowspan: 1,
            colspan: 1,
          });
        }
      });
    },
    arraySpanMethod({column}) {
      for (let i = 0; i < this.mergeList.length; i++) {
        if (column.property == this.mergeList[i].prop) {
          return [this.mergeList[i].rowspan, this.mergeList[i].colspan];
        }
      }
    },
    toggleExpand(row, field) {
      row[field] = !row[field];
    },
    getNodePath(node) {
      let fullPath = [];
      const loop = (node) => {
        if (node.level) fullPath.unshift(node.data);
        if (node.parent) loop(node.parent);
      };
      loop(node);
      return fullPath;
    },
    handleNodeClick(data, node) {
      if (this.treeActiveId == data[this.treeProps.value]) return;
      this.treeActiveId = data[this.treeProps.value];
      this.$refs.Search.treeReset();
      let queryJson = {};
      if (this.columnData.treeDataSource === "organize") {
        const nodePath = this.getNodePath(node);
        const currValue = nodePath.map((o) => o[this.treeProps.value]);
        queryJson = {[this.columnData.treeRelation]: currValue};
        this.treeActiveId = currValue;
      } else {
        queryJson = {
          [this.columnData.treeRelation]: data[this.treeProps.value],
        };
      }
      this.search(JSON.stringify(queryJson));
    },
    handleDel(id, index) {
      this.$confirm(
        this.$t("此操作将永久删除该数据, 是否继续？"),
        this.$t("提示"),
        {
          type: "warning",
        }
      )
        .then(() => {
          if (id) {
            deleteModel(this.modelId, {
              id: id,
              type: "delete",
            }).then((res) => {
              this.$message({
                type: "success",
                message: res.data.msg,
                duration: 1000,
                onClose: () => {
                  this.initData();
                },
              });
            });
          } else {
            this.list.splice(index, 1);
          }
        })
        .catch(() => {
        });
    },
    saveForRowEdit(row, status, candidateData) {
      if (this.isPreview)
        return this.$message({
          message: "功能预览不支持数据保存",
          type: "warning",
        });
      if (this.config.enableFlow == 1) {
        let query = {
          id: row.id,
          status: status || "1",
          candidateType: this.candidateType,
          formData: row,
          flowId: this.config.flowId,
          flowUrgent: 1,
        };
        if (candidateData) query = {...query, ...candidateData};
        const formMethod = query.id ? Update : Create;
        formMethod(query).then((res) => {
          this.$message({
            message: res.data.msg,
            type: "success",
            onClose: () => {
              this.candidateVisible = false;
              this.initData();
            },
          });
        });
      } else {
        let query = {
          id: row.id,
          data: JSON.stringify(row),
        };
        query.type = row.id ? "update" : "add";
        createModel(this.modelId, query).then((res) => {
          this.$message({
            message: res.data.msg,
            type: "success",
            onClose: () => {
              this.candidateVisible = false;
              this.initData();
            },
          });
        });
      }
    },
    submitForRowEdit(row) {
      this.currRow = row;
      if (this.isPreview)
        return this.$message({
          message: "功能预览不支持数据保存",
          type: "warning",
        });
      this.workFlowFormData = {
        id: row.id,
        formData: row,
        flowId: this.config.flowId,
      };
      Candidates(0, this.workFlowFormData)
        .then((res) => {
          let data = res.data;
          this.candidateType = data.type;
          if (data.type == 1) {
            this.branchList = res.data.list.filter((o) => o.isBranchFlow);
            this.candidateList = res.data.list.filter(
              (o) => !o.isBranchFlow && o.isCandidates
            );
            this.candidateVisible = true;
          } else if (data.type == 2) {
            this.branchList = [];
            this.candidateList = res.data.list.filter((o) => o.isCandidates);
            this.candidateVisible = true;
          } else {
            if (this.isCustomCopy) {
              this.branchList = [];
              this.candidateList = [];
              this.candidateVisible = true;
              return;
            }
            this.$confirm("您确定要提交当前流程吗, 是否继续?", "提示", {
              type: "warning",
            })
              .then(() => {
                this.saveForRowEdit(row, "0");
              })
              .catch(() => {
              });
          }
        })
        .catch(() => {
        });
    },
    submitCandidate(data) {
      this.saveForRowEdit(this.currRow, "0", data);
    },
    cancelRowEdit(row, index) {
      if (!row.id) return this.list.splice(index, 1);
      row.rowEdit = false;
      let item = JSON.parse(JSON.stringify(this.cacheList[index]));
      this.$set(this.list, index, item);
    },
    addHandleForRowEdit() {
      let item = {
        rowEdit: true,
      };
      for (let i = 0; i < this.columnData.columnList.length; i++) {
        let e = this.columnData.columnList[i];
        item[e.__vModel__] = e.__config__.defaultValue;
      }
      this.list.unshift(item);
    },
    //编辑/新增
    addOrUpdateHandle(id) {
      this.formData.disabled = false;
      if (this.config.enableFlow == 1) {
        let data = {
          id: id || "",
          enCode: this.config.flowEnCode,
          flowId: this.config.flowId,
          formType: 2,
          type: 1,
          opType: "-1",
          modelId: this.modelId,
          isPreview: this.isPreview,
        };
        this.flowVisible = true;
        this.$nextTick(() => {
          this.$refs.FlowBox.init(data);
        });
      } else {
        this.formVisible = true;
        this.$nextTick(() => {
          this.$refs.Form.init(
            this.formData,
            this.modelId,
            id,
            this.isPreview,
            this.columnData.useFormPermission
          );
        });
      }
    },
    headBtnsHandel(key) {
      if (key === "add") {
        if (this.columnData.type === 4) {
          this.addHandleForRowEdit();
        } else {
          this.addOrUpdateHandle();
        }
      }
      if (key == "download") {
        this.exportBoxVisible = true;
        this.$nextTick(() => {
          this.$refs.ExportBox.init(this.exportList);
        });
      }
      if (key == "upload") {
        this.uploadBoxVisible = true;
        this.$nextTick(() => {
          this.$refs.UploadBox.init(this.modelId);
        });
      }
      if (this.isPreview) return;
      if (key === "batchRemove") {
        this.batchRemove();
      }
    },
    handleSelectionChange(val) {
      const res = val.map((item) => item.id);
      this.multipleSelection = res;
    },
    batchRemove() {
      if (!this.multipleSelection.length) {
        this.$message({
          type: "error",
          message: "请选择一条数据",
        });
        return;
      }
      this.$confirm("您确定要删除这些数据吗, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          deleteModel(this.modelId, {
            id: this.multipleSelection.toString(),
            type: "delete",
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.data.msg,
              onClose: () => {
                this.initData();
              },
            });
          });
        })
        .catch(() => {
        });
    },
    download(data) {
      if (this.isPreview)
        return this.$message({
          message: "功能预览不支持数据导出",
          type: "warning",
        });
      let query = {...this.listQuery, ...data};
      exportModel(this.modelId, query).then((res) => {
        if (!res.data.url) return;
        this.sinoma.downloadFile(res.data.url);
        this.$refs.ExportBox.visible = false;
        this.exportBoxVisible = false;
      });
    },
    hanLinEdit(row) {
      row.rowEdit = true;
      let columnList = this.columnData.columnList;
      columnList.forEach((item) => {
        // switch
        if (item.jnpfKey == "switch") {
          if (row.hasOwnProperty(item.prop)) {
            if (row[item.prop] == item.activeTxt) {
              row[item.prop] = item["active-value"];
            } else if (row[item.prop] == item.inactiveTxt) {
              row[item.prop] = item["inactive-value"];
            }
          }
        }
        // cascader
        if (item.jnpfKey == "cascader") {
          if (row.hasOwnProperty(item.prop)) {
            let dataList = this.fnTree(item.options);
            let list = new Array();
            row[item.prop].forEach((rows) => {
              dataList.forEach((datas) => {
                if (rows == datas[item.props.props.label]) {
                  list.push(datas[item.props.props.value]);
                }
              });
            });
            row[item.prop] = list;
          }
        }
        // date
        if (item.jnpfKey == "date") {
          if (row.hasOwnProperty(item.prop)) {
            row[item.prop] = new Date(row[item.prop]).getTime();
          }
        }
        // treeSelect
        if (item.jnpfKey == 'treeSelect') {
          if (row.hasOwnProperty(item.prop)) {
            let dataList = this.fnTree(item.options);
            dataList.forEach((treeSelect) => {
              if (row[item.prop] == treeSelect[item.props.props.label]) {
                row[item.prop] = treeSelect[item.props.props.value]
              }
            })
          }
        }
      });
    },
    columnBtnsHandel(key, row, index) {
      if (key === "edit") {
        this.addOrUpdateHandle(row.id);
      }
      if (key === "detail") {
        this.goDetail(row.id, row);
      }
      if (key == "remove") {
        this.handleDel(row.id, index);
      }
    },
    //详情
    goDetail(id, row) {
      this.formData.disabled = true;
      if (this.config.enableFlow == 1) {
        let data = {
          id,
          enCode: this.config.flowEnCode,
          flowId: this.config.flowId,
          formType: 2,
          type: 1,
          opType: 0,
          modelId: this.modelId,
          isPreview: this.isPreview,
          status: row.flowState,
        };
        this.flowVisible = true;
        this.$nextTick(() => {
          this.$refs.FlowBox.init(data);
        });
      } else {
        this.formVisible = true;
        this.$nextTick(() => {
          this.$refs.Form.init(
            this.formData,
            this.modelId,
            id,
            this.columnData.useFormPermission
          );
        });
      }
    },
    sortChange({column, prop, order}) {
      this.listQuery.sort = order == "ascending" ? "asc" : "desc";
      this.listQuery.sidx = !order ? "" : prop;
      this.initData();
    },
    refresh(isRefresh) {
      this.formVisible = false;
      if (isRefresh) this.initData();
    },
    closeFlow(isRefresh) {
      this.flowVisible = false;
      if (isRefresh) this.initData();
    },
    reset() {
      this.listQuery.sort = this.defaultListQuery.sort;
      this.listQuery.sidx = this.defaultListQuery.sidx;
      if (this.columnData.type === 2) {
        let obj = {[this.columnData.treeRelation]: this.treeActiveId};
        let queryJson = this.treeActiveId ? JSON.stringify(obj) : "";
        this.search(queryJson);
      } else {
        this.search("");
      }
    },
    searchData(queryJson) {
      if (this.columnData.type === 2 && this.treeActiveId) {
        queryJson = JSON.parse(queryJson);
        queryJson = {
          [this.columnData.treeRelation]: this.treeActiveId,
          ...queryJson,
        };
        queryJson = JSON.stringify(queryJson);
      }
      this.search(queryJson);
    },
    search(queryJson) {
      if (this.isPreview) return;
      if (!queryJson)
        this.$refs.treeBox && this.$refs.treeBox.setCurrentKey(null);
      this.listQuery.queryJson = queryJson;
      this.listQuery.currentPage = 1;
      this.initData();
    },
    openSuperQuery() {
      this.superQueryVisible = true;
      this.$nextTick(() => {
        this.$refs.SuperQuery.init();
      });
    },
    superQuery(queryJson) {
      if (this.isPreview) return;
      this.listQuery.superQueryJson = queryJson;
      this.listQuery.currentPage = 1;
      this.initData();
    },
    customBtnsHandel(item, row, index) {
      const parameter = {
        data: row,
        index,
        request: this.request,
        toast: this.$message,
        refresh: this.initData,
      };
      const func = this.sinoma.getScriptFunc.call(this, item.func);
      if (!func) return;
      func.call(this, parameter);
    },
    request(url, method, data) {
      if (!url) return;
      return request({
        url: url,
        method: method || "GET",
        data: data || {},
      });
    },
    setTableLoadFunc() {
      const JNPFTable = this.$refs.tableRef.$refs.JNPFTable;
      const parameter = {
        data: this.list,
        attributes: JNPFTable._props,
        events: JNPFTable._events,
        methods: JNPFTable,
        tableRef: JNPFTable,
        request: this.request,
      };
      const func = this.sinoma.getScriptFunc.call(
        this,
        this.columnData.funcs.afterOnload.func
      );
      if (!func) return;
      func.call(this, parameter);
    },
    buildOptions() {
      this.columnData.columnList.forEach((cur) => {
        const config = cur.__config__;
        if (dyOptionsList.indexOf(config.jnpfKey) > -1) {
          let isTreeSelect =
            config.jnpfKey === "treeSelect" || config.jnpfKey === "cascader";
          if (config.dataType === "dictionary") {
            if (!config.dictionaryType) return;
            getChildList(1, 500, config.dictionaryType).then((res) => {
              res.data.data.forEach((item) => {
                item.fullName = item.dictValue;
                item.enCode = item.dictKey;
              });
              isTreeSelect
                ? (cur.options = res.data.data)
                : (cur.__slot__.options = res.data.data);
            });
          }
          if (config.dataType === "dynamic") {
            if (!config.propsUrl) return;
            let query = {
              paramList: config.templateJson || [],
            };
            getDataInterfaceRes(config.propsUrl, query).then((res) => {
              let data = res.data.data;
              if (Array.isArray(data)) {
                isTreeSelect
                  ? (cur.options = data)
                  : (cur.__slot__.options = data);
              } else {
                isTreeSelect ? (cur.options = []) : (cur.__slot__.options = []);
              }
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.SNOWS-common-title h2 {
  margin: 0;
}
</style>
